import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";
import ListingScreen from "../../mycomponents/CRUD/ListingScreen";

export const collaborationfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {

    const formFields = [
        {
            label: 'Title',
            key: 'title',
            type: 'text',
            placeholder: 'Title',
            visible: true,
            width: '50%',
            tab: "General",
        },
        {
            label: 'Year',
            key: 'year',
            type: 'number',
            placeholder: 'Year',
            visible: true,
            width: '50%',
            tab: "General",
        },
        {
            label: 'Description',
            key: 'description',
            type: 'text',
            placeholder: 'Name',
            visible: true,
            width: '100%',
            tab: "General",
        },
    ]

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%'
            });
        });
    }

    return formFields;
}

export const collaborationMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const collaborationMain = {
    fields: collaborationfields,
    mobileCard: null
}