import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";

export const locationfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {
  const formFields = [
    {
      type: 'divider',
      heading: 'Location Details',
      visible: true,
      span: 24,
    },
    {
      label: 'Name',
      key: 'name',
      type: 'text',
      placeholder: 'Enter Location Name',
      visible: true,
      width: '25%',
      required: true,
    },
    {
      label: 'Address',
      key: 'address',
      type: 'text',
      placeholder: 'Enter Address',
      visible: true,
      width: '25%',
      value: 'Address'
    },
    {
      label: 'Latitude',
      key: 'latitude',
      type: 'number',
      placeholder: 'Enter Latitude',
      visible: true,
      width: '25%',
      required: true,
      value: 23.8103
    },
    {
      label: 'Longitude',
      key: 'longitude',
      type: 'number',
      placeholder: 'Enter Longitude',
      visible: true,
      width: '25%',
      required: true,
      value: 77.4126
    }
  ];

  if (globalData.organization_id == 56) {
    formFields[2].visible = false;  
    formFields[3].visible = false;
    formFields[4].visible = false;
  }

  const CustomFields = response.CustomFields;

  if (CustomFields && CustomFields.length > 0) {
    CustomFields.forEach((field) => {
      formFields.push({
        label: field.name,
        key: 'cf.' + field.key,
        type: field.type,
        placeholder: field.placeholder,
        visible: true,
        width: '25%'
      });
    });
  }

  return formFields;
}

export const locationMobileCard = (item, index, getFieldValue) => {
  return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
    <View>
      <Text>TEST</Text>
      <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
    </View>
  </Card>;
}

export const locationMain = {
  fields: locationfields,
  mobileCard: null
}