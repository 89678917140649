import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";
import ListingScreen from "../../mycomponents/CRUD/ListingScreen";
import { CustomSelect } from "../../mycomponents/DynamicForm";
import { Platform } from "react-native";

// date: { type: Date, required: true },
// location_id: { type: Types.ObjectId, default: null, ref: 'Locations' },
// location: { type: new mongoose.Schema({ _id: { type: mongoose.Schema.Types.ObjectId }, name: { type: String }, }), default: {} },

// amount: { type: Number, required: true },
// remarks: { type: String, required: false },


export const safaPaymentInfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {

    const formFields = [
        {
            label: 'Date',
            key: 'date',
            type: 'date',
            placeholder: 'Select the date',
            visible: true,
            width: '25%',
            tab: 'General',
            value: value ? value.date : new Date()
        },
        {
            label: 'Location',
            key: 'location_id',
            type: 'select',
            placeholder: 'Select the location',
            visible: true,
            width: '25%',
            tab: 'General',
            value: value ? value.location_id : null,
            options: (response.Locations || []).map((item) => {
                return {
                    value: item._id,
                    label: item.name
                }
            }),
        },
        {
            label: 'Amount',
            key: 'amount',
            type: 'number',
            placeholder: 'Enter the amount',
            visible: true,
            width: '25%',
            tab: 'General',
            value: value ? value.amount : 0
        },
        {
            label: 'Remarks',
            key: 'remarks',
            type: 'textarea',
            placeholder: 'Enter the remarks',
            visible: true,
            width: '25%',
            tab: 'General',
            value: value ? value.remarks : ""
        }
    ]

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%'
            });
        });
    }

    return formFields;
}

export const safaPaymentInMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

const QuickFilterComponent = ({ customTabFilter, setCustomTabFilter, createData }) => {
    const [location_id, setLocationId] = React.useState("ALL");

    useEffect(() => {
        if (location_id == "ALL") {
            const filter = {
                ...customTabFilter,
                location_id: null
            };

            delete filter.location_id;
            setCustomTabFilter(filter)
        } else {
            setCustomTabFilter({
                ...customTabFilter,
                location_id: location_id
            })
        }
    }, [location_id])

    return <View right row marginB-10>
        <View style={{
            width: Platform.OS == 'web' ? 300 : '100%',
        }}>
            <CustomSelect
            independent={true}
            value={location_id} options={[
                { label: "All Locations", value: "ALL" },
                ...(createData?.Locations || []).map((item) => {
                    return {
                        label: item.name,
                        value: item._id
                    }
                })
            ]} onChange={(value) => {
                setLocationId(value);
            }} />
        </View>
    </View>;
}

export const safaPaymentInMain = {
    fields: safaPaymentInfields,
    mobileCard: null,
    QuickFilterComponent: QuickFilterComponent,
}