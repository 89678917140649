// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useRef, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions, RefreshControl } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, Avatar, TouchableOpacity } from 'react-native-ui-lib';
import DynamicForm, { Col, CustomModal, CustomNumberInput, CustomSelect, DangerButton, Heading, Row, SecondaryButton, CustomTextField, Label, CustomCheckbox } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../mycomponents/state"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import { GlobalMasterStore } from "../../../../../mycomponents/state/PermissionStore"
import dayjs from "dayjs"
import { Camera, CameraType } from 'expo-camera';
import Svg, { G, Path } from "react-native-svg"
const isDesktop = Dimensions.get('window').width >= 768;

const Footer = (props) => {

    // useEffect(() => {
    //     const script = document.createElement('script');
    //     script.src = "https://assets.calendly.com/assets/external/widget.js";
    //     script.async = true;
    //     document.body.appendChild(script);

    //     const link = document.createElement('link');
    //     link.href = "https://assets.calendly.com/assets/external/widget.css";
    //     link.rel = "stylesheet";
    //     document.body.appendChild(link);

    //     const style = document.createElement('style');
    //     style.innerHTML = `
    //         .calendly-badge-content span {
    //             display: none !important;
    //         }

    //         .calendly-badge-content {
    //             font-family: 'SourceSansProBold' !important;
    //             letter-spacing: 1px;
    //             text-transform: uppercase;
    //             font-weight: 500 !important;
    //         }
    //     `;
    //     document.head.appendChild(style);

    //     setTimeout(() => {
    //         Calendly.initBadgeWidget({
    //             url: 'https://calendly.com/faizankhan2595/15min',
    //             text: 'Book An Appointment',
    //             color: "rgb(210, 180, 140)",
    //             textColor: '#ffffff',
    //             branding: true
    //         });
    //     }, 1000);

    //     return () => {
    //         document.body.removeChild(script);
    //         document.body.removeChild(link);
    //         document.head.removeChild(style);

    //         Calendly.destroyBadgeWidget();
    //     }
    // }, []);

    let navigation = useNavigation();
    let navigate = null;

    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.navigate("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.navigate(path, params);
            }
        }
    };

    return (
        <>
            {/* <Image assetGroup="images" assetName="webFooterNw" style={{
                width: Dimensions.get('window').width,
                height: 200,
                resizeMode: 'cover',
            }} /> */}
            {/* <img src={require('../../../../../../assets/images/webFooterNw.png')} style={{
                width: Dimensions.get('window').width,
                height: 200,
                resizeMode: 'cover',
            }} /> */}

            <View style={{
                backgroundColor: '#f2f6f9',
                width: '100%',
                padding: 20,
            }}>

                <View style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                }}>

                    <View marginT-15 marginB-15 style={{
                        backgroundColor: 'rgb(211, 223, 234)',
                        height: 1.5,
                        width: '100%',
                    }}></View>
                </View>
                <View style={{
                    backgroundColor: '#f2f6f9',
                    display: 'flex',
                    flexDirection: isDesktop ? 'row' : 'column',
                    width: '100%',
                }}>
                    <View style={{
                        width: isDesktop ? '34%' : '100%',
                        padding: isDesktop ? 35 : 10,
                    }}>
                        <Col width={"100%"} style={{
                            textAlign: 'left',
                        }}>
                            <View style={{
                                paddingBottom: 15,
                                textTransform: 'uppercase',
                            }}>
                                <Text h1 style={{
                                    letterSpacing: 1
                                }}>ABOUT US</Text>
                            </View>
                            <View marginB-10 style={{
                                maxWidth: 300
                            }}>
                                <Text value>
                                    {`We connect communities through city-focused online marketplaces, making it simple for neighbors to buy and sell locally. Our platform brings the convenience of online shopping to your neighborhood. Whether you're decluttering your home or finding unique local treasures, our city-specific marketplaces make trading easy and secure. Join your city's vibrant marketplace today.`}
                                </Text>
                            </View>
                        </Col>
                    </View>

                    <View style={{
                        width: isDesktop ? '22%' : '100%',
                        padding: isDesktop ? 35 : 10,
                    }}>
                        <Col width={"100%"} style={{
                            textAlign: 'left',
                        }}>
                            <View style={{
                                paddingBottom: 15,
                                textTransform: 'uppercase',
                            }}>
                                <Text h1 style={{
                                    letterSpacing: 1
                                }}>INFORMATION</Text>
                            </View>
                            <View marginB-10>
                                <TouchableOpacity onPress={() => {
                                    navigationFn("shop/about-us", {});
                                }}>
                                    <Text>About Us</Text>
                                </TouchableOpacity>
                            </View>
                            <View marginB-10>
                                <TouchableOpacity onPress={() => {
                                    navigationFn("shop/contact-us", {});
                                }}>
                                    <Text>Contact Us</Text>
                                </TouchableOpacity>
                            </View>
                        </Col>
                    </View>

                    <View style={{
                        width: isDesktop ? '22%' : '100%',
                        padding: isDesktop ? 35 : 10,
                    }}>
                        <Col width={"100%"} style={{
                            textAlign: 'left',
                        }}>
                            <View style={{
                                paddingBottom: 15,
                                textTransform: 'uppercase',
                            }}>
                                <Text h1 style={{
                                    letterSpacing: 1
                                }}>CUSTOMER SERVICE</Text>
                            </View>
                            <View marginB-10>
                                <TouchableOpacity onPress={() => {
                                    navigationFn("shop/privacy-policy", {});
                                }}>
                                    <Text>Privacy Policy</Text>
                                </TouchableOpacity>
                            </View>
                            <View marginB-10>
                                <TouchableOpacity onPress={() => {
                                    navigationFn("shop/cancellation-refund-policy", {});
                                }}>
                                    <Text>Cancellation & Refund Policy</Text>
                                </TouchableOpacity>
                            </View>
                            <View marginB-10>
                                <TouchableOpacity onPress={() => {
                                    navigationFn("shop/terms-and-conditions", {});
                                }}>
                                    <Text>Terms & Conditions</Text>
                                </TouchableOpacity>
                            </View>
                            <View marginB-10>
                                <TouchableOpacity onPress={() => {
                                    navigationFn("shop/shipping-delivery-policy", {});
                                }}>
                                    <Text>Shipping Delivery Policy</Text>
                                </TouchableOpacity>
                            </View>
                        </Col>
                    </View>

                    <View style={{
                        width: isDesktop ? '22%' : '100%',
                        padding: isDesktop ? 35 : 10,
                    }}>
                        <Col width={"100%"} style={{
                            textAlign: 'left',
                        }}>
                            <View style={{
                                paddingBottom: 15,
                                textTransform: 'uppercase',
                            }}>
                                <Text h1 style={{
                                    letterSpacing: 1
                                }}>CONTACT US</Text>
                            </View>
                            <View marginB-10>
                                <Text>
                                    {`MarketPlace, 123 Main Street, New York, NY 10001`}
                                </Text>
                            </View>
                            <View marginB-10>
                                <Text>Phone: (123) 456-7890</Text>
                            </View>
                            <View marginB-10>
                                <Text>Email: marketplace@gmail.com</Text>
                            </View>
                        </Col>
                    </View>
                </View>

                <View style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                }}>

                    <View marginB-15 style={{
                        backgroundColor: 'rgb(211, 223, 234)',
                        height: 1.5,
                        width: '100%',
                    }}></View>
                    <Text>© 2024, MarketPlace. All Rights Reserved.</Text>
                </View>
            </View>


        </>);
};

export default Footer;