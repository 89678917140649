import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";
import ListingScreen from "../../mycomponents/CRUD/ListingScreen";
import { CustomSelect, CustomTextField, Label } from "../../mycomponents/DynamicForm";
import { Platform } from "react-native";



export const safaPaymentOutfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {

    const CustomComponent = React.memo(({ field, useForm, tKey, tIndex }) => {
        const location_id = useForm((state) => state.formObject['location_id']);
        const employee_id = useForm((state) => state.formObject['employee_id']);
        const third_party_details = useForm((state) => state.formObject['third_party_details']);
        const setFormObject = useForm((state) => state.setFormObject);

        return <View flex>
            <View flex>
                {Label({ label: 'Location' })}
                <CustomSelect
                    independent={true}
                    label="Location"
                    value={location_id}
                    onChange={(value) => {
                        setFormObject({
                            employee_id: null,
                            location_id: value
                        });
                    }}
                    creatableAction={(props) => {
                        const params = {
                            ...(props.id ? { isEdit: true, id: props.id } : {}),
                            ...(props.search ? { is_default_values: true, name: props.search } : {})
                        };
                        OpenModalCustom({
                            newModule: 'Locations',
                            field_key: 'location_id',
                            params
                        });
                    }}
                    options={(response.Locations || []).map((item) => {
                        return {
                            value: item._id,
                            label: item.name
                        }
                    })}
                />
            </View>
            <View flex marginT-10>
                {Label({ label: 'Employee' })}
                <CustomSelect
                    label="Employee"
                    independent={true}
                    value={employee_id}
                    options={(response.Employees || []).filter((item) => (item?.location_ids || []).includes(location_id) || item._id == "6776403bda8c6534330f7f95").map((item) => {
                        return {
                            value: item._id,
                            label: item.name
                        }
                    })}
                    creatableAction={(props) => {
                        const params = {
                            ...(props.id ? { isEdit: true, id: props.id } : {}),
                            ...(props.search ? { is_default_values: true, name: props.search } : {})
                        };
                        OpenModalCustom({
                            newModule: 'Employees',
                            field_key: 'employee_id',
                            params
                        });
                    }}
                    onChange={(value) => {
                        setFormObject({
                            employee_id: value
                        });
                    }}
                />
            </View>
            {employee_id == "6776403bda8c6534330f7f95" && <View flex marginT-10>
                {Label({ label: 'Third Party Details' })}
                <CustomTextField
                    independent={true}
                    value={third_party_details}
                    onChange={(value) => {
                        setFormObject({
                            third_party_details: value
                        });
                    }}
                />
            </View>}
        </View>
    });


    const formFields = [
        {
            label: 'Date',
            key: 'date',
            type: 'date',
            placeholder: 'Select the date',
            visible: true,
            width: '25%',
            tab: 'General',
            value: value ? value.date : new Date()
        },
        // {
        //     label: 'Location',
        //     key: 'location_id',
        //     type: 'select',
        //     placeholder: 'Select the location',
        //     visible: true,
        //     width: '25%',
        //     tab: 'General',
        //     value: value ? value.location_id : null,
        //     options: (response.Locations || []).map((item) => {
        //         return {
        //             value: item._id,
        //             label: item.name
        //         }
        //     }),
        //     onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
        //         setObj({
        //             updateVisiblity: Math.random()
        //         });
        //     }
        // },
        // {
        //     label: 'Employee',
        //     key: 'employee_id',
        //     type: 'select',
        //     placeholder: 'Select the employee',
        //     visible: true,
        //     width: '25%',
        //     tab: 'General',
        //     value: value ? value.employee_id : null,
        //     // options: (response.Employees || []).filter((item) => (item?.locations || []).includes(value?.location_id)).map((item) => {
        //     options: (response.Employees || []).filter((item) => {
        //         console.log(item?.locations, value?.location_id, (item?.locations || []).includes(value?.location_id));

        //         return (item?.locations || []).includes(value?.location_id)
        //     }).map((item) => {
        //         return {
        //             value: item._id,
        //             label: item.name
        //         }
        //     }),
        // },
        {
            key: 'custom_component',
            type: 'custom',
            visible: true,
            width: '50%',
            tab: 'General',
            component: CustomComponent,
        },
        {
            label: 'Amount',
            key: 'amount',
            type: 'number',
            placeholder: 'Enter the amount',
            visible: true,
            width: '25%',
            tab: 'General',
            value: value ? value.amount : 0
        },
        {
            label: 'Remarks',
            key: 'remarks',
            type: 'textarea',
            placeholder: 'Enter the remarks',
            visible: true,
            width: '25%',
            tab: 'General',
            value: value ? value.remarks : ""
        }
    ]

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%'
            });
        });
    }

    return formFields;
}

export const safaPaymentOutMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

const QuickFilterComponent = ({ customTabFilter, setCustomTabFilter, createData }) => {
    const [location_id, setLocationId] = React.useState("ALL");

    useEffect(() => {
        if (location_id == "ALL") {
            const filter = {
                ...customTabFilter,
                location_id: null
            };

            delete filter.location_id;
            setCustomTabFilter(filter)
        } else {
            setCustomTabFilter({
                ...customTabFilter,
                location_id: location_id
            })
        }
    }, [location_id])

    return <View right row marginB-10>
        <View style={{
            width: Platform.OS == 'web' ? 300 : '100%',
        }}>
            <CustomSelect
                independent={true}
                value={location_id} options={[
                    { label: "All Locations", value: "ALL" },
                    ...(createData?.Locations || []).map((item) => {
                        return {
                            label: item.name,
                            value: item._id
                        }
                    })
                ]} onChange={(value) => {
                    setLocationId(value);
                }} />
        </View>
    </View>;
}

export const safaPaymentOutMain = {
    fields: safaPaymentOutfields,
    mobileCard: null,
    QuickFilterComponent,
}