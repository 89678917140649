// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useRef, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions, RefreshControl } from "react-native"
import { api } from "../../../../../../services/api"
import { colors, spacing } from "../../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, Avatar, TouchableOpacity } from 'react-native-ui-lib';
import DynamicForm, { Col, CustomModal, CustomNumberInput, CustomSelect, DangerButton, Heading, Row, SecondaryButton, CustomTextField, Label, CustomCheckbox } from "../../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../../mycomponents/state"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import { GlobalMasterStore } from "../../../../../../mycomponents/state/PermissionStore"
import dayjs from "dayjs"
import { Camera, CameraType } from 'expo-camera';
import Svg, { G, Path } from "react-native-svg"
import Header from "../Header"
import Footer from "../Footer"
import { Icon } from "react-native-eva-icons"
import { App } from "antd"
import { Swiper, SwiperSlide } from 'swiper/react';

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { ProductCard } from "../Home"

const isDesktop = Dimensions.get('window').width >= 768;

const Product = (props) => {
    const { message, modal } = App.useApp();
    const [product, setProduct] = useState({});
    const [currentImage, setCurrentImage] = useState(0);
    const cart = GlobalMasterStore(state => state.cart);
    const setCart = GlobalMasterStore(state => state.setCart);

    const [selectedSeller, setSelectedSeller] = useState(null);

    const [relatedProducts, setRelatedProducts] = useState([]);

    const wishList = GlobalMasterStore(state => state.wishList);
    const setWishList = GlobalMasterStore(state => state.setWishList);

    const params = useParams();

    let navigation = useNavigation();
    let navigate = null;

    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.navigate("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.navigate(path, params);
            }
        }
    };

    const getProduct = async () => {
        const response = await api.customRoute("shop/getProduct", {
            id: params.id,
        });

        console.log(response?.data);

        console.log("product", response?.data);

        const product = response?.data;
        const parent_product = response?.data?.parent;

        product.meta = { ...(parent_product?.meta || {}), ...(product?.meta || {}) }

        setProduct(response?.data);
        setRelatedProducts(response?.relatedProducts);

        if (response?.data?.image_urls && response?.data?.image_urls?.length > 0) {
            setCurrentImage(0);
        }
    };

    useEffect(() => {
        getProduct();

        const selectedSeller = cart.find((item) => item._id == params.id)?.seller;
        setSelectedSeller(selectedSeller);
    }, [params.id, cart]);

    return (<>
        <ScrollView style={{
            height: Dimensions.get("window").height,
        }}>
            <Card>
                <View style={{
                    position: 'relative',
                }}>
                    <Header theme={"white_bg"} />
                    <View style={[{
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#fff",
                        overflow: "hidden",
                        height: 70,
                        minHeight: 70,
                        borderWidth: 1,
                        borderColor: "#e5e7eb",
                    }]}></View>
                </View>
                <Row>
                    <View style={{
                        width: '100%',
                        padding: 50,
                    }}>
                        <View row={isDesktop} style={{
                            width: '100%'
                        }}>
                            <Col width={isDesktop ? "60%" : '100%'}>
                                <View row style={{
                                    width: '100%',
                                    marginTop: -10
                                }}>
                                    <View>
                                        {product.image_urls && product.image_urls.map((image_url, i) => <View
                                            key={i}
                                            style={{
                                                padding: 10,
                                            }}>
                                            <TouchableOpacity onPress={() => {
                                                setCurrentImage(i);
                                            }}
                                            ><Image resizeMode="cover" source={{ uri: image_url.url }} style={{ width: 100, height: 100 }} />
                                            </TouchableOpacity>
                                        </View>)}
                                    </View>
                                    <View flexG style={{
                                        padding: 10,
                                        width: (Dimensions.get("window").width / 2) - 100,
                                    }}>
                                        {Platform.OS === 'web' ? <img src={product.image_urls ? product.image_urls[currentImage]?.url : null} style={{ width: '100%', height: "auto" }} /> : <Image resizeMode="cover" source={{ uri: product.image_urls ? product.image_urls[currentImage]?.url : null }} style={{ width: '100%', height: Dimensions.get("window").height - 100 }} />}
                                    </View>
                                </View>
                            </Col>

                            <Col width={isDesktop ? "40%" : '100%'}
                                style={{
                                    paddingLeft: isDesktop ? 40 : 0,
                                }}>
                                <View style={{
                                    paddingBottom: 5,
                                }}>
                                    <Text style={{
                                        color: "#000",
                                        fontSize: 24,
                                        fontFamily: 'SourceSansProBold'
                                    }}>{product.name}</Text>
                                </View>

                                <View style={{
                                    paddingBottom: 15,
                                }}>
                                    <div dangerouslySetInnerHTML={{ __html: product.description }}></div>
                                </View>

                                <Row style={{
                                    marginTop: 10,
                                }}>
                                    {product.meta && Object.keys(product.meta).map((key, i) => <Col key={i} width="50%">
                                        <View style={{
                                            width: '100%',
                                        }}>
                                            <View style={{
                                            }}>
                                                <Text style={{
                                                    fontSize: 13,
                                                    color: '#7e818c',
                                                }}>
                                                    {key}
                                                </Text>
                                            </View>
                                            <View marginT-5>
                                                <Text style={{
                                                    fontSize: 15,
                                                }}>
                                                    {typeof product.meta[key] === 'string' ? product.meta[key] : product.meta[key]?.join(", ")}
                                                </Text>
                                            </View>

                                            <View marginT-15 style={{
                                                height: 1,
                                                backgroundColor: '#e5e7eb',
                                                width: '100%',
                                            }}></View>
                                        </View>
                                    </Col>)}
                                </Row>

                                {product?.options?.map((option, i) => <><View key={i} marginB-10>
                                    <Text style={{
                                        fontFamily: 'SourceSansProBold',
                                    }}>Select {option.name}</Text>
                                </View>

                                    <View style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start',
                                    }}>
                                        <View style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}>
                                            {option?.value?.map((value, i) => <TouchableOpacity
                                                key={i}
                                                onPress={() => {
                                                    const variant_products = product?.variant_products;

                                                    const matchingVariantProduct = variant_products.find((variant_product) => {
                                                        let isMatching = false;
                                                        Object.keys(variant_product.meta).forEach((key) => {
                                                            if (key == option.name) {
                                                                if (variant_product.meta[key].includes(value)) {
                                                                    // isMatching = true;
                                                                    console.log("variant_product.meta", variant_product.meta);
                                                                    console.log("product?.options", product?.options);
                                                                    console.log("product?.meta", product?.meta);

                                                                    // check for other options as well that matches current selection
                                                                    const other_options = product?.options.filter((option) => option.name != key);

                                                                    if (other_options.length == 0) {
                                                                        isMatching = true;
                                                                    } else {
                                                                        let isOtherOptionsMatching = false;
                                                                        other_options.forEach((other_option) => {
                                                                            if (JSON.stringify(product?.meta[other_option.name]) == JSON.stringify(variant_product.meta[other_option.name])) {
                                                                                isOtherOptionsMatching = true;
                                                                            }
                                                                        });

                                                                        if (isOtherOptionsMatching) {
                                                                            isMatching = true;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        });
                                                        return isMatching;
                                                    });

                                                    console.log("variant_products", variant_products);
                                                    console.log("matchingVariantProduct", matchingVariantProduct);

                                                    if (matchingVariantProduct) {
                                                        navigationFn("shop/product/" + matchingVariantProduct?.sku + "/" + matchingVariantProduct?._id);
                                                    }
                                                }}
                                                style={(product.meta || {})[option.name] == value ? {
                                                    backgroundColor: 'none',
                                                    height: '40px',
                                                    width: '40px',
                                                    marginRight: 10,
                                                    border: '1px solid rgb(234 212 171)',
                                                    fontFamily: 'SourceSansProBold',
                                                    color: '#000',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    background: "rgb(11, 122, 202)1c"
                                                } : {
                                                    height: '40px',
                                                    width: '40px',
                                                    color: '#fff',
                                                    border: '1px solid rgb(229, 231, 235)',
                                                    fontFamily: 'SourceSansProBold',
                                                    marginRight: 10,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}>
                                                <Text>
                                                    {value}
                                                </Text>
                                            </TouchableOpacity>
                                            )}
                                        </View>
                                    </View></>)}

                                {product.sellers && product.sellers.length > 0 && <View style={{
                                    width: '100%',
                                    marginTop: 10,
                                }}>
                                    <Text style={{
                                        fontFamily: 'SourceSansProBold',
                                        fontSize: 18,
                                    }}>Available at</Text>

                                    {product.sellers.map((seller, i) => <TouchableOpacity
                                        key={i}
                                        onPress={() => {
                                            setSelectedSeller(seller);

                                            if(cart.filter(item => item._id == product._id).length > 0) {
                                                setCart(cart.map((item) => {
                                                    if(item._id == product._id) {
                                                        return {
                                                            ...item,
                                                            seller: seller,
                                                        };
                                                    }

                                                    return item;
                                                }));
                                            }
                                        }}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            padding: 10,
                                            border: '1px solid #e5e7eb',
                                            marginTop: 10,
                                        }}>
                                        <View>
                                            {selectedSeller?.seller_id == seller.seller_id ? <Icon name="checkmark-circle-2" fill="rgb(11, 122, 202)" width={20} height={20} /> : <Icon name="radio-button-off-outline" fill="rgb(11, 122, 202)" width={20} height={20} />}
                                        </View>
                                        <View>
                                            <Text style={{
                                                fontFamily: 'SourceSansProBold',
                                                fontSize: 16,
                                            }}>{seller.seller?.name}</Text>
                                        </View>
                                        <View>
                                            <Text style={{
                                                fontFamily: 'SourceSansProBold',
                                                fontSize: 16,
                                            }}>Rs. {seller.price}</Text>
                                        </View>
                                    </TouchableOpacity>)}
                                </View>}

                                <View row style={{
                                    width: '100%',
                                }}>
                                    <View style={{
                                        flexGrow: 1,
                                        paddingTop: 10,
                                    }}>
                                        {cart.filter(item => item._id == product._id).length > 0 ?
                                            <TouchableOpacity row center onPress={() => {
                                                navigationFn("shop/checkout");
                                            }} style={{
                                                backgroundColor: 'rgb(11, 122, 202)',
                                                border: '2px solid rgb(11, 122, 202)',
                                                color: '#000',
                                                padding: 10,
                                                width: '100%',
                                                alignItems: 'center',
                                                fontFamily: 'SourceSansProBold',
                                                paddingVertical: 10,
                                                paddingHorizontal: 30,
                                            }}>
                                                <Text color="white">Go To Cart</Text>
                                                <View marginL-5>
                                                    <Icon name="shopping-cart-outline" fill="white" width={20} height={20} />
                                                </View>
                                            </TouchableOpacity> :
                                            <TouchableOpacity row center onPress={() => {
                                                if(!selectedSeller) {
                                                    message.error("Please select a seller");
                                                    return;
                                                }

                                                setCart([...cart, {
                                                    _id: product._id,
                                                    seller: selectedSeller,
                                                    name: product.name,
                                                    image_urls: product.image_urls,
                                                    meta: (product.meta || {}),
                                                    sales_rate: product.sales_rate,
                                                    quantity: 1
                                                }]);
                                                message.success("Product added to cart");
                                            }} style={{
                                                backgroundColor: 'rgb(11, 122, 202)',
                                                border: '2px solid rgb(11, 122, 202)',
                                                color: '#000',
                                                padding: 10,
                                                width: '100%',
                                                fontFamily: 'SourceSansProBold',
                                                paddingVertical: 10,
                                                paddingHorizontal: 30,
                                                flexDirection: 'row',
                                            }}>
                                                <Text color="white">Add To Cart</Text>
                                                <View marginL-5>
                                                    <Icon name="shopping-cart-outline" fill="white" width={20} height={20} />
                                                </View>
                                            </TouchableOpacity>}
                                    </View>

                                    {/* <View style={{
                                        width: 60,
                                        paddingTop: 10,
                                    }}>
                                        {wishList.filter(item => item._id == product._id).length > 0 ?
                                            <TouchableOpacity row center onPress={() => {
                                                setWishList(wishList.filter(item => item._id != product._id));
                                                message.success("Product removed from wishlist");
                                            }} style={{
                                                backgroundColor: '#fff',
                                                border: '2px solid rgb(11, 122, 202)',
                                                color: 'rgb(11, 122, 202)',
                                                padding: 10,
                                                width: '100%',
                                                alignItems: 'center',
                                                fontFamily: 'SourceSansProBold',
                                                paddingVertical: 10,
                                                paddingHorizontal: 10,
                                            }}
                                            >
                                                <View>
                                                    <Icon name="heart" fill="rgb(11, 122, 202)" width={20} height={20} />
                                                </View>
                                            </TouchableOpacity> :
                                            <TouchableOpacity row center onPress={() => {
                                                setWishList([...(wishList || []), {
                                                    _id: product._id,
                                                    name: product.name,
                                                    image_urls: product.image_urls,
                                                    meta: (product.meta || {}),
                                                    sales_rate: product.sales_rate,
                                                }]);
                                                message.success("Product added to wishlist");
                                            }} style={{
                                                backgroundColor: '#fff',
                                                border: '2px solid rgb(11, 122, 202)',
                                                color: 'rgb(11, 122, 202)',
                                                padding: 10,
                                                width: '100%',
                                                alignItems: 'center',
                                                fontFamily: 'SourceSansProBold',
                                                paddingVertical: 10,
                                                paddingHorizontal: 10,
                                            }}
                                            >
                                                <View>
                                                    <Icon name="heart-outline" fill="rgb(11, 122, 202)" width={20} height={20} />
                                                </View>
                                            </TouchableOpacity>}
                                    </View> */}
                                </View>
                            </Col>
                        </View>
                    </View>
                </Row>

                <View center marginB-50 marginT-50>
                    <Text style={{
                        fontFamily: 'SourceSansProBold',
                        letterSpacing: 1,
                        fontSize: 18
                    }}>RELATED PRODUCTS</Text>
                    <View row style={{
                        position: 'relative',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        marginTop: 20,
                    }}>
                        <View style={{
                            width: 8,
                            height: 8,
                            borderRadius: 10,
                            backgroundColor: '#2a88b2',
                            zIndex: 2,
                        }}></View>
                        <View style={{
                            width: '50%',
                            height: 1,
                            backgroundColor: '#2a88b2',
                            zIndex: 1,
                        }}></View>
                        <View style={{
                            width: 8,
                            height: 8,
                            borderRadius: 10,
                            backgroundColor: '#2a88b2',
                            zIndex: 2,
                        }}></View>
                    </View>
                </View>

                <Swiper
                    modules={[Navigation]}
                    style={{
                        height: 450,
                        width: '100%',
                    }}
                    spaceBetween={0}
                    slidesPerView={1}
                    loop={true}
                    breakpoints={{
                        640: {
                            slidesPerView: 1,
                            spaceBetween: 0,
                        },
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 0,
                        },
                        1024: {
                            slidesPerView: 4,
                            spaceBetween: 0,
                        },
                    }}
                    navigation={true}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                >
                    {relatedProducts.map((item, index) => <SwiperSlide key={index}>
                        <ProductCard navigationFn={navigationFn} item={item} />
                    </SwiperSlide>)}
                </Swiper>

                <View style={{
                    height: 150, 
                }}></View>

                <Footer />
            </Card >
        </ScrollView >
    </>);
};

export default Product;