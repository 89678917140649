// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useRef, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions, RefreshControl, DeviceEventEmitter } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, Avatar, TouchableOpacity } from 'react-native-ui-lib';
import DynamicForm, { Col, CustomModal, CustomNumberInput, CustomSelect, DangerButton, Heading, Row, SecondaryButton, CustomTextField, Label, CustomCheckbox } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../mycomponents/state"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import { GlobalMasterStore } from "../../../../../mycomponents/state/PermissionStore"
import dayjs from "dayjs"
import { Camera, CameraType } from 'expo-camera';
import Svg, { G, Path } from "react-native-svg"
import Header from "./Header"
import Footer from "./Footer"
import { Icon } from "react-native-eva-icons"
import { create } from 'zustand';
import { invoiceItemAdjustment } from "../../../../../models/accountancy/transaction"
import { App } from 'antd';

const isDesktop = Dimensions.get('window').width >= 768;

const useFormObject = create((set, get) => ({
    formObject: {},
    setFormObject: (keyValue) => set((state) => ({ formObject: { ...state.formObject, ...keyValue } })),
    setFormObjectArray: (array_key, array_index, field_key, value) => set((state) => {
        if (typeof field_key === 'object') {
            const newFormObject = { ...state.formObject };
            newFormObject[array_key][array_index] = { ...newFormObject[array_key][array_index], ...field_key };
            return { formObject: newFormObject };
        } else {
            const newFormObject = { ...state.formObject };
            // newFormObject[array_key][array_index][field_key] = value;
            const newArray = newFormObject[array_key];
            newArray[array_index][field_key] = value;
            newFormObject[array_key] = newArray;
            return { formObject: newFormObject };
        }
    }),
    getFormObjectArrayValue: (array_key, array_index, field_key) => get().formObject[array_key][array_index][field_key],
    getFormObject: () => get().formObject,
}))

const Sellers = (props) => {
    const { message, modal } = App.useApp();

    const cart = GlobalMasterStore(state => state.cart);
    const setCart = GlobalMasterStore(state => state.setCart);

    const [phone, setPhone] = useState("");
    const [country, setCountry] = useState("India");
    const [name, setName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [apartment, setApartment] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [pincode, setPincode] = useState("");
    const [quantity, setQuantity] = useState(1);
    const [paymentType, setPaymentType] = useState("Razorpay");

    let navigation = useNavigation();
    let navigate = null;

    const formObject = useFormObject((state) => state.formObject);
    const getFormObject = useFormObject((state) => state.getFormObject);
    const setArrValue = useFormObject((state) => state.setFormObjectArray);
    const setFormObject = useFormObject((state) => state.setFormObject);
    const setGlobalData = GlobalMasterStore((state) => state.setGlobalData);
    const globalData = GlobalMasterStore((state) => state.globalData);

    const [products, setProducts] = useState([]);

    const OpenModalCustom = ({ newModule, field_key, array = false, params = null }) => {
        DeviceEventEmitter.addListener("reloadData" + "POS", (event) => {
            if (!event) {
                DeviceEventEmitter.removeAllListeners("reloadData" + "POS");
                if (Platform.OS == 'web') {
                    setOpenModal({ [moduleName]: null })
                } else {

                    navigation.pop();
                }
                return;
            }

            const newObj = getFormObject();

            if (event?.deleted) {
                DeviceEventEmitter.removeAllListeners("reloadData" + "POS");
                let object = { ...newObj };

                if (event?.id) {
                    if (array) {
                        const oldValues = object[field_key] ? object[field_key] : [];
                        object[field_key] = oldValues.filter((id) => id != event?.id);
                    } else {
                        object[field_key] = null;
                    }
                }

                getModuleCreateData({ ...object, disable_auto_line_item: true });

                if (Platform.OS == 'web') {
                    setOpenModal({
                        [newModule]: null
                    })
                } else {
                    goBack();
                }
                return;
            }

            let object = { ...newObj };

            if (array) {
                const oldValues = object[field_key] ? object[field_key] : [];
                object[field_key] = [...new Set([...oldValues, event._id])]
            } else {
                object[field_key] = event._id;
            }

            getModuleCreateData({ ...object, disable_auto_line_item: true });

            if (Platform.OS == 'web') {
                setOpenModal({
                    [newModule]: null
                })
            } else {
                goBack();
            }

            DeviceEventEmitter.removeAllListeners("reloadData" + "POS");
        })

        if (Platform.OS == 'web') {
            let data = {
                [newModule]: {
                    isVisible: true,
                    moduleName: [newModule],
                    viewOnly: false,
                    afterSaveEvent: 'reloadData' + "POS",
                    ignoreQueryParams: true,
                    ...(params ? params : {})
                }
            };

            setOpenModal(data)
        } else {
            navigationFn('New', {
                moduleName: newModule,
                afterSaveEvent: 'reloadData' + "POS",
                ...(params ? params : {})
            })
        }
    }

    const moduleName = "Invoices";
    const { fields, dataTabs, preferencesFields, ExtraSaveComponent } = api.getForm(moduleName);
    const params = {};

    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.navigate("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.navigate(path, params);
            }
        }
    };

    const [sellers, setSellers] = useState([]);
    const [search, setSearch] = useState("");

    const getSellers = async () => {
        const response = await api.customRoute("shop/getSellers", {
            search,
        });

        setSellers(response.data);
    }

    useEffect(() => {
        getSellers();
    }, []);

    return (<>
        <ScrollView style={{
            height: Dimensions.get("window").height,
        }}>
            <Card>
                <Header />
                <View style={[{
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#fff",
                    overflow: "hidden",
                    height: 70,
                    minHeight: 70,
                    borderWidth: 1,
                    borderColor: "#e5e7eb",
                }]}>
                </View>
                <View center>
                    <View style={{
                        maxWidth: 1200,
                        width: '100%',
                    }}>
                        <Row>
                            <Col width={isDesktop ? "100%" : '100%'} style={{
                                padding: isDesktop ? 60 : 30,
                            }}>
                                <View
                                 style={{
                                    width: '100%',
                                    padding: 20,
                                    backgroundColor: "white",
                                    borderRadius: 10,
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                 }}
                                >
                                    {sellers.map((seller) => (<Card
                                        onPress={() => {
                                            navigationFn("shop/products", {
                                                seller: seller._id,
                                            });
                                        }}
                                        key={seller._id}
                                        style={{
                                            width: isDesktop ? "30%" : "100%",
                                            margin: 10,
                                            padding: 10,
                                            borderRadius: 10,
                                        }}
                                       >
                                        <Card.Image
                                            source={{ uri: seller.image || "https://www.inkapps.io/assets/images/logo.png" }}
                                            style={{
                                                width: "100%",
                                                height: 200,
                                                borderRadius: 10,
                                            }}
                                        />
                                        <View marginT-10 center>
                                            <Text style={{
                                                fontSize: 14,
                                                fontFamily: "SourceSansProSemiBold",
                                            }}>{seller.name}</Text>
                                        </View>
                                        </Card>
                                    ))} 
                                </View>
                            </Col>
                        </Row>
                    </View>
                </View>
                <Footer />
            </Card>
        </ScrollView>
    </>);
};

export default Sellers;


const STATES = [
    {
        "_id": "653399d6f16f979e73ca92a0",
        "id": 4023,
        "name": "Andaman and Nicobar Islands",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "AN",
        "type": "Union territory",
        "latitude": "11.74008670",
        "longitude": "92.65864010",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.169Z",
        "updatedAt": "2023-10-21T09:28:54.169Z",
        "__v": 0,
        "label": "Andaman and Nicobar Islands",
        "value": "Andaman and Nicobar Islands"
    },
    {
        "_id": "653399d6f16f979e73ca92a3",
        "id": 4017,
        "name": "Andhra Pradesh",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "AP",
        "type": "state",
        "latitude": "15.91289980",
        "longitude": "79.73998750",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.174Z",
        "updatedAt": "2023-10-21T09:28:54.174Z",
        "__v": 0,
        "label": "Andhra Pradesh",
        "value": "Andhra Pradesh"
    },
    {
        "_id": "653399d6f16f979e73ca92a6",
        "id": 4024,
        "name": "Arunachal Pradesh",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "AR",
        "type": "state",
        "latitude": "28.21799940",
        "longitude": "94.72775280",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.179Z",
        "updatedAt": "2023-10-21T09:28:54.179Z",
        "__v": 0,
        "label": "Arunachal Pradesh",
        "value": "Arunachal Pradesh"
    },
    {
        "_id": "653399d6f16f979e73ca92a9",
        "id": 4027,
        "name": "Assam",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "AS",
        "type": "state",
        "latitude": "26.20060430",
        "longitude": "92.93757390",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.182Z",
        "updatedAt": "2023-10-21T09:28:54.182Z",
        "__v": 0,
        "label": "Assam",
        "value": "Assam"
    },
    {
        "_id": "653399d6f16f979e73ca92ac",
        "id": 4037,
        "name": "Bihar",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "BR",
        "type": "state",
        "latitude": "25.09607420",
        "longitude": "85.31311940",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.187Z",
        "updatedAt": "2023-10-21T09:28:54.187Z",
        "__v": 0,
        "label": "Bihar",
        "value": "Bihar"
    },
    {
        "_id": "653399d6f16f979e73ca92af",
        "id": 4031,
        "name": "Chandigarh",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "CH",
        "type": "Union territory",
        "latitude": "30.73331480",
        "longitude": "76.77941790",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.191Z",
        "updatedAt": "2023-10-21T09:28:54.191Z",
        "__v": 0,
        "label": "Chandigarh",
        "value": "Chandigarh"
    },
    {
        "_id": "653399d6f16f979e73ca92b2",
        "id": 4040,
        "name": "Chhattisgarh",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "CT",
        "type": "state",
        "latitude": "21.27865670",
        "longitude": "81.86614420",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.195Z",
        "updatedAt": "2023-10-21T09:28:54.195Z",
        "__v": 0,
        "label": "Chhattisgarh",
        "value": "Chhattisgarh"
    },
    {
        "_id": "653399d6f16f979e73ca92b5",
        "id": 4033,
        "name": "Dadra and Nagar Haveli and Daman and Diu",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "DH",
        "type": "Union territory",
        "latitude": "20.39737360",
        "longitude": "72.83279910",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.199Z",
        "updatedAt": "2023-10-21T09:28:54.199Z",
        "__v": 0,
        "label": "Dadra and Nagar Haveli and Daman and Diu",
        "value": "Dadra and Nagar Haveli and Daman and Diu"
    },
    {
        "_id": "653399d6f16f979e73ca92b8",
        "id": 4021,
        "name": "Delhi",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "DL",
        "type": "Union territory",
        "latitude": "28.70405920",
        "longitude": "77.10249020",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.204Z",
        "updatedAt": "2023-10-21T09:28:54.204Z",
        "__v": 0,
        "label": "Delhi",
        "value": "Delhi"
    },
    {
        "_id": "653399d6f16f979e73ca92bb",
        "id": 4009,
        "name": "Goa",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "GA",
        "type": "state",
        "latitude": "15.29932650",
        "longitude": "74.12399600",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.207Z",
        "updatedAt": "2023-10-21T09:28:54.207Z",
        "__v": 0,
        "label": "Goa",
        "value": "Goa"
    },
    {
        "_id": "653399d6f16f979e73ca92be",
        "id": 4030,
        "name": "Gujarat",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "GJ",
        "type": "state",
        "latitude": "22.25865200",
        "longitude": "71.19238050",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.212Z",
        "updatedAt": "2023-10-21T09:28:54.212Z",
        "__v": 0,
        "label": "Gujarat",
        "value": "Gujarat"
    },
    {
        "_id": "653399d6f16f979e73ca92c1",
        "id": 4007,
        "name": "Haryana",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "HR",
        "type": "state",
        "latitude": "29.05877570",
        "longitude": "76.08560100",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.217Z",
        "updatedAt": "2023-10-21T09:28:54.217Z",
        "__v": 0,
        "label": "Haryana",
        "value": "Haryana"
    },
    {
        "_id": "653399d6f16f979e73ca92c4",
        "id": 4020,
        "name": "Himachal Pradesh",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "HP",
        "type": "state",
        "latitude": "31.10482940",
        "longitude": "77.17339010",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.223Z",
        "updatedAt": "2023-10-21T09:28:54.223Z",
        "__v": 0,
        "label": "Himachal Pradesh",
        "value": "Himachal Pradesh"
    },
    {
        "_id": "653399d6f16f979e73ca92c7",
        "id": 4029,
        "name": "Jammu and Kashmir",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "JK",
        "type": "Union territory",
        "latitude": "33.27783900",
        "longitude": "75.34121790",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.228Z",
        "updatedAt": "2023-10-21T09:28:54.228Z",
        "__v": 0,
        "label": "Jammu and Kashmir",
        "value": "Jammu and Kashmir"
    },
    {
        "_id": "653399d6f16f979e73ca92ca",
        "id": 4025,
        "name": "Jharkhand",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "JH",
        "type": "state",
        "latitude": "23.61018080",
        "longitude": "85.27993540",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.233Z",
        "updatedAt": "2023-10-21T09:28:54.233Z",
        "__v": 0,
        "label": "Jharkhand",
        "value": "Jharkhand"
    },
    {
        "_id": "653399d6f16f979e73ca92cd",
        "id": 4026,
        "name": "Karnataka",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "KA",
        "type": "state",
        "latitude": "15.31727750",
        "longitude": "75.71388840",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.239Z",
        "updatedAt": "2023-10-21T09:28:54.239Z",
        "__v": 0,
        "label": "Karnataka",
        "value": "Karnataka"
    },
    {
        "_id": "653399d6f16f979e73ca92d0",
        "id": 4028,
        "name": "Kerala",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "KL",
        "type": "state",
        "latitude": "10.85051590",
        "longitude": "76.27108330",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.244Z",
        "updatedAt": "2023-10-21T09:28:54.244Z",
        "__v": 0,
        "label": "Kerala",
        "value": "Kerala"
    },
    {
        "_id": "653399d6f16f979e73ca92d3",
        "id": 4852,
        "name": "Ladakh",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "LA",
        "type": "Union territory",
        "latitude": "34.22684750",
        "longitude": "77.56194190",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.252Z",
        "updatedAt": "2023-10-21T09:28:54.252Z",
        "__v": 0,
        "label": "Ladakh",
        "value": "Ladakh"
    },
    {
        "_id": "653399d6f16f979e73ca92d6",
        "id": 4019,
        "name": "Lakshadweep",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "LD",
        "type": "Union territory",
        "latitude": "10.32802650",
        "longitude": "72.78463360",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.258Z",
        "updatedAt": "2023-10-21T09:28:54.258Z",
        "__v": 0,
        "label": "Lakshadweep",
        "value": "Lakshadweep"
    },
    {
        "_id": "653399d6f16f979e73ca92d9",
        "id": 4039,
        "name": "Madhya Pradesh",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "MP",
        "type": "state",
        "latitude": "22.97342290",
        "longitude": "78.65689420",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.265Z",
        "updatedAt": "2023-10-21T09:28:54.265Z",
        "__v": 0,
        "label": "Madhya Pradesh",
        "value": "Madhya Pradesh"
    },
    {
        "_id": "653399d6f16f979e73ca92dc",
        "id": 4008,
        "name": "Maharashtra",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "MH",
        "type": "state",
        "latitude": "19.75147980",
        "longitude": "75.71388840",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.273Z",
        "updatedAt": "2023-10-21T09:28:54.273Z",
        "__v": 0,
        "label": "Maharashtra",
        "value": "Maharashtra"
    },
    {
        "_id": "653399d6f16f979e73ca92df",
        "id": 4010,
        "name": "Manipur",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "MN",
        "type": "state",
        "latitude": "24.66371730",
        "longitude": "93.90626880",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.279Z",
        "updatedAt": "2023-10-21T09:28:54.279Z",
        "__v": 0,
        "label": "Manipur",
        "value": "Manipur"
    },
    {
        "_id": "653399d6f16f979e73ca92e2",
        "id": 4006,
        "name": "Meghalaya",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "ML",
        "type": "state",
        "latitude": "25.46703080",
        "longitude": "91.36621600",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.285Z",
        "updatedAt": "2023-10-21T09:28:54.285Z",
        "__v": 0,
        "label": "Meghalaya",
        "value": "Meghalaya"
    },
    {
        "_id": "653399d6f16f979e73ca92e5",
        "id": 4036,
        "name": "Mizoram",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "MZ",
        "type": "state",
        "latitude": "23.16454300",
        "longitude": "92.93757390",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.291Z",
        "updatedAt": "2023-10-21T09:28:54.291Z",
        "__v": 0,
        "label": "Mizoram",
        "value": "Mizoram"
    },
    {
        "_id": "653399d6f16f979e73ca92e8",
        "id": 4018,
        "name": "Nagaland",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "NL",
        "type": "state",
        "latitude": "26.15843540",
        "longitude": "94.56244260",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.295Z",
        "updatedAt": "2023-10-21T09:28:54.295Z",
        "__v": 0,
        "label": "Nagaland",
        "value": "Nagaland"
    },
    {
        "_id": "653399d6f16f979e73ca92eb",
        "id": 4013,
        "name": "Odisha",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "OR",
        "type": "state",
        "latitude": "20.95166580",
        "longitude": "85.09852360",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.300Z",
        "updatedAt": "2023-10-21T09:28:54.300Z",
        "__v": 0,
        "label": "Odisha",
        "value": "Odisha"
    },
    {
        "_id": "653399d6f16f979e73ca92ee",
        "id": 4011,
        "name": "Puducherry",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "PY",
        "type": "Union territory",
        "latitude": "11.94159150",
        "longitude": "79.80831330",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.305Z",
        "updatedAt": "2023-10-21T09:28:54.305Z",
        "__v": 0,
        "label": "Puducherry",
        "value": "Puducherry"
    },
    {
        "_id": "653399d6f16f979e73ca92f1",
        "id": 4015,
        "name": "Punjab",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "PB",
        "type": "state",
        "latitude": "31.14713050",
        "longitude": "75.34121790",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.311Z",
        "updatedAt": "2023-10-21T09:28:54.311Z",
        "__v": 0,
        "label": "Punjab",
        "value": "Punjab"
    },
    {
        "_id": "653399d6f16f979e73ca92f4",
        "id": 4014,
        "name": "Rajasthan",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "RJ",
        "type": "state",
        "latitude": "27.02380360",
        "longitude": "74.21793260",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.316Z",
        "updatedAt": "2023-10-21T09:28:54.316Z",
        "__v": 0,
        "label": "Rajasthan",
        "value": "Rajasthan"
    },
    {
        "_id": "653399d6f16f979e73ca92f7",
        "id": 4034,
        "name": "Sikkim",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "SK",
        "type": "state",
        "latitude": "27.53297180",
        "longitude": "88.51221780",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.321Z",
        "updatedAt": "2023-10-21T09:28:54.321Z",
        "__v": 0,
        "label": "Sikkim",
        "value": "Sikkim"
    },
    {
        "_id": "653399d6f16f979e73ca92fa",
        "id": 4035,
        "name": "Tamil Nadu",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "TN",
        "type": "state",
        "latitude": "11.12712250",
        "longitude": "78.65689420",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.326Z",
        "updatedAt": "2023-10-21T09:28:54.326Z",
        "__v": 0,
        "label": "Tamil Nadu",
        "value": "Tamil Nadu"
    },
    {
        "_id": "653399d6f16f979e73ca92fd",
        "id": 4012,
        "name": "Telangana",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "TG",
        "type": "state",
        "latitude": "18.11243720",
        "longitude": "79.01929970",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.331Z",
        "updatedAt": "2023-10-21T09:28:54.331Z",
        "__v": 0,
        "label": "Telangana",
        "value": "Telangana"
    },
    {
        "_id": "653399d6f16f979e73ca9300",
        "id": 4038,
        "name": "Tripura",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "TR",
        "type": "state",
        "latitude": "23.94084820",
        "longitude": "91.98815270",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.336Z",
        "updatedAt": "2023-10-21T09:28:54.336Z",
        "__v": 0,
        "label": "Tripura",
        "value": "Tripura"
    },
    {
        "_id": "653399d6f16f979e73ca9303",
        "id": 4022,
        "name": "Uttar Pradesh",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "UP",
        "type": "state",
        "latitude": "26.84670880",
        "longitude": "80.94615920",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.341Z",
        "updatedAt": "2023-10-21T09:28:54.341Z",
        "__v": 0,
        "label": "Uttar Pradesh",
        "value": "Uttar Pradesh"
    },
    {
        "_id": "653399d6f16f979e73ca9306",
        "id": 4016,
        "name": "Uttarakhand",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "UT",
        "type": "state",
        "latitude": "30.06675300",
        "longitude": "79.01929970",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.346Z",
        "updatedAt": "2023-10-21T09:28:54.346Z",
        "__v": 0,
        "label": "Uttarakhand",
        "value": "Uttarakhand"
    },
    {
        "_id": "653399d6f16f979e73ca9309",
        "id": 4853,
        "name": "West Bengal",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "WB",
        "type": "state",
        "latitude": "22.98675690",
        "longitude": "87.85497550",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.351Z",
        "updatedAt": "2023-10-21T09:28:54.351Z",
        "__v": 0,
        "label": "West Bengal",
        "value": "West Bengal"
    }
]

