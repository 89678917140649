import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";
import ListingScreen from "../../mycomponents/CRUD/ListingScreen";

export const videofields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {

    const formFields = [
        {
            label: 'Title',
            key: 'name',
            type: 'text',
            placeholder: 'Name',
            visible: true,
            width: '75%',
            tab: "General",
        },
        {
            label: 'Video Link',
            key: 'video_link',
            type: 'text',
            placeholder: 'Video Link',
            visible: true,
            width: '75%',
            tab: "General",
        },
        {
            label: 'Banner Image',
            key: 'banner_image',
            type: 'upload',
            placeholder: 'Banner Image',
            visible: true,
            width: '100%',
            mimeType: 'image/*',
            moduleName: "Videos",
            tab: "General",
        }
    ]

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%'
            });
        });
    }

    return formFields;
}

export const videoMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const videoMain = {
    fields: videofields,
    mobileCard: null
}