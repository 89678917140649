// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useRef, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions, RefreshControl } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, Avatar, TouchableOpacity } from 'react-native-ui-lib';
import DynamicForm, { Col, CustomModal, CustomNumberInput, CustomSelect, DangerButton, Heading, Row, SecondaryButton, CustomTextField, Label, CustomCheckbox } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../mycomponents/state"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import { GlobalMasterStore } from "../../../../../mycomponents/state/PermissionStore"
import dayjs from "dayjs"
import { Camera, CameraType } from 'expo-camera';
import Svg, { G, Path } from "react-native-svg"
import Header from "./Header"
import Footer from "./Footer"
import { Icon } from "react-native-eva-icons"
import { App } from "antd"

import { ProductCard } from "./Home"
import RenderHTML from "react-native-render-html"

const isDesktop = Dimensions.get('window').width >= 768;

const AboutUs = (props) => {
    const { message, modal } = App.useApp();
    const [product, setProduct] = useState({});
    const [currentImage, setCurrentImage] = useState(0);
    const cart = GlobalMasterStore(state => state.cart);
    const setCart = GlobalMasterStore(state => state.setCart);

    const [relatedProducts, setRelatedProducts] = useState([]);

    const wishList = GlobalMasterStore(state => state.wishList);
    const setWishList = GlobalMasterStore(state => state.setWishList);

    const params = useParams();

    let navigation = useNavigation();
    let navigate = null;

    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.navigate("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.navigate(path, params);
            }
        }
    };

    const title = "About us";
    const content = `<h2>Our Story</h2>
<p>MarketPlace was born from a simple yet powerful observation: while e-commerce has revolutionized how we shop, the heart of commerce still beats strongest in our local communities. Founded in 2024, our journey began when we noticed the growing disconnect between local sellers and their potential customers in the digital age.</p>
<p>Our founders, having experienced firsthand the challenges of running local businesses, realized that most existing platforms were either too broad in scope or too complex for local merchants. They envisioned a solution that would bridge this gap - a platform that would bring the convenience of modern e-commerce to neighborhood commerce.</p>
<p>What started as a small pilot project in one city quickly evolved into a movement, as both sellers and buyers embraced the idea of a dedicated local marketplace. Today, we operate in multiple cities, each with its own unique marketplace ecosystem.</p>
<h2>Our Mission</h2>
<p>At MarketPlace, we believe in empowering local communities by creating digital spaces where neighborhood commerce can thrive. Our mission is to revitalize local economies by connecting buyers with sellers in their immediate vicinity, reducing delivery times, supporting local businesses, and building stronger community bonds.</p>
<p>We're committed to making online selling and buying accessible to everyone. Whether you're a small business owner looking to expand your reach, an artisan wanting to showcase your crafts, or a resident searching for local products, our platform is designed to serve you.</p>
<h2>What Sets Us Apart</h2>
<p>Unlike traditional e-commerce platforms, we focus exclusively on city-specific marketplaces. This localized approach ensures that buyers can find products available in their vicinity, while sellers can reach their most relevant customer base. Our platform is built on three core principles: community, convenience, and trust.</p>
<p>We verify all our sellers and implement strict quality control measures to ensure a safe and reliable trading environment. Our user-friendly interface makes it easy for anyone to list items or browse products, while our secure payment system protects both buyers and sellers.</p>
<h2>Community Impact</h2>
<p>The impact of our platform extends beyond mere transactions. By facilitating local trade, we help reduce the environmental footprint of shopping through shorter delivery distances. We support local entrepreneurship by providing an affordable platform for businesses to establish their online presence.</p>
<p>Our marketplace has become a hub for discovering unique local products, from homemade crafts to fresh produce. We've seen countless success stories of small businesses expanding their customer base and individuals finding new markets for their products.</p>
<h2>Looking Forward</h2>
<p>As we continue to grow, our commitment to local communities remains unwavering. We're constantly innovating and improving our platform based on user feedback and changing needs. Our goal is to expand to more cities while maintaining the personal, local touch that makes each marketplace unique.</p>
<p>We envision a future where every city has its own thriving digital marketplace, where local commerce is strengthened by technology rather than replaced by it. Join us in building stronger, more connected communities through local commerce.</p>
<h2>Join Our Community</h2>
<p>Whether you're a buyer looking for local treasures or a seller ready to reach new customers, we invite you to be part of our growing community. Together, we're not just creating a marketplace; we're building stronger, more connected neighborhoods one transaction at a time.</p>`;

    return (<>
        <ScrollView style={{
            height: Dimensions.get("window").height,
        }}>
            <Card>
                <View style={{
                    position: 'relative',
                }}>
                    <Header theme={"white_bg"} />
                    <View style={[{
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#fff",
                        overflow: "hidden",
                        height: 70,
                        minHeight: 70,
                        borderWidth: 1,
                        borderColor: "#e5e7eb",
                    }]}>
                    </View>
                </View>
                <Row>
                    <View style={{
                        width: '100%',
                        padding: isDesktop ? 50 : 20,
                    }}>
                        <View row={isDesktop} style={{
                            width: '100%'
                        }}>
                            <Col width={isDesktop ? "100%" : '100%'}
                                style={{
                                    paddingLeft: isDesktop ? 40 : 0,
                                }}>
                                <View style={{
                                    paddingBottom: 5,
                                }}>
                                    <Text style={{
                                        color: "#000",
                                        fontSize: 24,
                                        fontFamily: 'SourceSansProBold'
                                    }}>{title}</Text>
                                </View>


                                <View style={{
                                    paddingBottom: 15,
                                }}>
                                    {Platform.OS == 'web' ? <div dangerouslySetInnerHTML={{ __html: content }} /> : <RenderHTML contentWidth={Dimensions.get('window').width - 40} source={{ html: content }} />}
                                </View>
                            </Col>
                        </View>
                    </View>
                </Row>

                <Footer />
            </Card >
        </ScrollView >
    </>);
};

export default AboutUs;