import React, { useEffect } from 'react';
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, Avatar, TouchableOpacity, Carousel } from 'react-native-ui-lib';
import Chart from "react-apexcharts";
import { GlobalMasterStore } from '../../../../../../mycomponents/state/PermissionStore';
import { Icon } from 'react-native-eva-icons';
import { CustomDatePicker, CustomModal, Heading, SecondaryButton } from '../../../../../../mycomponents/DynamicForm';
import { Platform } from 'react-native';
import moment from 'moment';
import { api } from '../../../../../../services/api';
import { AssetsComponentIcon, CashInComponentIcon, CashOutComponentIcon, LiabilityComponentIcon, NetProfitComponentIcon, SalesDashboardComponentIcon } from '../HomeIcons';
import { ScrollView } from 'react-native-gesture-handler';
import { RND } from '../../../../../../models/accountancy/transaction';

const SalesCard = ({ }) => {

}

const getBusinessDay = () => {
    const currentMoment = moment();
    if (currentMoment.hour() < 5) {
        return currentMoment.subtract(1, 'day');
    }
    return currentMoment;
};

export const HomeDashboard = (props) => {
    const organization_id = GlobalMasterStore((state) => state.globalData.organization_id);
    const location_id = GlobalMasterStore((state) => state.globalData.location_id);
    const [startDate, setStartDate] = React.useState(getBusinessDay().toDate());
    const [endDate, setEndDate] = React.useState(getBusinessDay().toDate());
    const [labelType, setLabelType] = React.useState('day-time');
    const [totalSales, setTotalSales] = React.useState(0);
    const [totalPurchases, setTotalPurchases] = React.useState(0);
    const [totalPaymentReceived, setTotalPaymentReceived] = React.useState(0);
    const [showPaymentReceivedDetailModal, setShowPaymentReceivedDetailModal] = React.useState(false);
    const [showPaymentMadeDetailModal, setShowPaymentMadeDetailModal] = React.useState(false);
    const [totalPaymentMade, setTotalPaymentMade] = React.useState(0);
    const [totalAssets, setTotalAssets] = React.useState(0);
    const [totalLiabilities, setTotalLiabilities] = React.useState(0);
    const [totalNetProfit, setTotalNetProfit] = React.useState(0);
    const [topProducts, setTopProducts] = React.useState([]);
    const [salesChart, setSalesChart] = React.useState({
        xaxis: [],
        data: []
    });
    const [reloadChart, setReloadChart] = React.useState(0);

    const [paymentDetailsArray, setPaymentDetailsArray] = React.useState([]);
    const [paymentMadeDetailsArray, setPaymentMadeDetailsArray] = React.useState([]);

    const getTotalSales = async () => {
        try {
            const response = await api.customRoute('homeCard/TotalSales', {
                location_id: location_id,
                startDate: moment(startDate).startOf('day').add(5, "hours").toDate(),
                endDate: moment(endDate).endOf('day').add(5, "hours").toDate(),
            });

            setTotalSales(response.data);
        } catch (e) {

        }
    }

    const getTotalPurchases = async () => {
        try {
            const response = await api.customRoute('homeCard/TotalPurchases', {
                location_id: location_id,
                startDate: moment(startDate).startOf('day').add(5, "hours").toDate(),
                endDate: moment(endDate).endOf('day').add(5, "hours").toDate(),
            });

            setTotalPurchases(response.data);
        } catch (e) {

        }
    }

    const getTotalPaymentReceived = async () => {
        try {
            const response = await api.customRoute('homeCard/TotalPaymentReceived', {
                location_id: location_id,
                startDate: moment(startDate).startOf('day').add(5, "hours").toDate(),
                endDate: moment(endDate).endOf('day').add(5, "hours").toDate(),
            });

            setTotalPaymentReceived(response.data);
        } catch (e) {

        }
    }

    const getPaymentReceivedSummaryByCashAccounts = async () => {
        try {
            const response = await api.customRoute('homeCard/PaymentReceivedSummaryByCashAccounts', {
                location_id: location_id,
                startDate: moment(startDate).startOf('day').add(5, "hours").toDate(),
                endDate: moment(endDate).endOf('day').add(5, "hours").toDate(),
            });

            setPaymentDetailsArray(response.data);
        } catch (e) {

        }
    }

    const getPaymentMadeSummaryByCashAccounts = async () => {
        try {
            const response = await api.customRoute('homeCard/PaymentMadeSummaryByCashAccounts', {
                location_id: location_id,
                startDate: moment(startDate).startOf('day').add(5, "hours").toDate(),
                endDate: moment(endDate).endOf('day').add(5, "hours").toDate(),
            });

            setPaymentMadeDetailsArray(response.data);
        } catch (e) {

        }
    }

    const getTotalPaymentMade = async () => {
        try {
            const response = await api.customRoute('homeCard/TotalPaymentMade', {
                location_id: location_id,
                startDate: moment(startDate).startOf('day').add(5, "hours").toDate(),
                endDate: moment(endDate).endOf('day').add(5, "hours").toDate(),
            });

            setTotalPaymentMade(response.data);
        } catch (e) {

        }
    }

    const getSalesChart = async () => {
        try {
            const response = await api.customRoute('homeCard/SalesChart', {
                location_id: location_id,
                startDate: moment(startDate).startOf('day').add(5, "hours").toDate(),
                endDate: moment(endDate).endOf('day').add(5, "hours").toDate(),
                labelType: labelType
            });

            console.log(response);

            setSalesChart({
                xaxis: response.xaxis,
                data: response.data
            });

            setReloadChart(Math.random());
        } catch (e) {
        }
    }

    const getItemSales = async () => {
        try {
            const response = await api.customRoute('getReport', {
                "reportName": "SalesByItem",
                "page": 1,
                "limit": 20,
                "sortColumn": "",
                "sortDirection": "",
                "filters": [],
                "search": "",
                "groupBy": null,
                "startDate": moment(startDate).startOf('day').add(5, "hours").toDate(),
                "endDate": moment(endDate).endOf('day').add(5, "hours").toDate(),
                "location_id": location_id,
                "ignore_date_changes": true
            });

            const data = response.data.data;
            const filteredData = data.filter((item) => item.quantity > 0);
            const sortedData = filteredData.sort((a, b) => b.quantity - a.quantity);

            setTopProducts(sortedData);
        } catch (e) {

        }
    }

    useEffect(() => {
        const start = moment(startDate).startOf('day');
        const end = moment(endDate).endOf('day');

        const diff = end.diff(start, 'days');

        if (diff <= 1) {
            setLabelType('day-time');
        } else if (diff <= 60) {
            setLabelType('dates');
        } else {
            setLabelType('month-year');
        }
    }, [startDate, endDate]);

    useEffect(() => {
        getTotalSales();
        getTotalPurchases();
        getSalesChart();
        getTotalPaymentReceived();
        getPaymentReceivedSummaryByCashAccounts();
        getPaymentMadeSummaryByCashAccounts();
        getTotalPaymentMade();
        getItemSales();
    }, [location_id, startDate, endDate, labelType]);


    return (<View padding-15>
        <View style={{ marginTop: 0, width: '100%' }}>
            <View spread row>
                <Text text70 style={{ fontWeight: 'bold' }}>{"Dashboard"}</Text>
                <View row centerV>
                    <View marginR-10>
                        <CustomDatePicker allowClear={false} placeholder="Start Date" value={startDate} onChange={(e) => {
                            //const date = moment(e).startOf('day').add(5, "hours").toDate();
                            setStartDate(e);
                        }} />
                    </View>
                    <View>
                        <Icon name='arrow-forward-outline' width={16} height={16} fill='#53535f' />
                    </View>
                    <View marginL-10>
                        <CustomDatePicker allowClear={false} placeholder="End Date" value={endDate} onChange={(e) => {
                            //const date = moment(e).endOf('day').add(5, "hours").toDate();
                            setEndDate(e);
                        }} />
                    </View>
                </View>
            </View>

            {Platform.OS == 'ios' ? <View style={{ overflow: 'hidden' }}>
                <View
                    style={{
                        borderStyle: 'dashed',
                        borderWidth: 1,
                        borderColor: '#d9d9d9',
                        margin: -2,
                        marginTop: 10,
                    }}>
                    <View style={{ height: 20, width: 200 }} />
                </View>
            </View> : <View style={{
                borderBottomColor: '#d9d9d9',
                borderBottomWidth: 1,
                width: '100%',
                marginBottom: 0,
                marginTop: 10,
                borderStyle: 'dashed',
            }} />}
        </View>

        <View marginT-20 row>
            <Card marginR-10 padding-16 row backgroundColor='#706fd3' style={{
                flexBasis: '24%'
            }}>

                <SalesDashboardComponentIcon />

                <View marginL-8>
                    <Text darkCardTitleBig color='white'>
                        {/* {organization_id == 24 ? (totalPaymentReceived || 0).toFixed(0) : (totalSales || 0).toFixed(0)} */}
                        {/* {(totalSales || 0).toFixed(0)} */}
                        {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format((totalSales || 0))}
                    </Text>
                    <View>
                        <Text lightCardTitle color='white'>Total Sales</Text>
                    </View>
                </View>
            </Card>
            <Card marginR-10 padding-16 row backgroundColor='#8e44ad' style={{
                flexBasis: '24%'
            }}>

                <SalesDashboardComponentIcon />

                <View marginL-8>
                    <Text darkCardTitleBig color='white'>
                        {/* {organization_id == 24 ? (totalPaymentReceived || 0).toFixed(0) : (totalSales || 0).toFixed(0)} */}
                        {/* {(totalPurchases || 0).toFixed(0)} */}
                        {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format((totalPurchases || 0))}
                    </Text>
                    <View>
                        <Text lightCardTitle color='white'>Total Purchases</Text>
                    </View>
                </View>
            </Card>
            <Card onPress={() => {
                setShowPaymentReceivedDetailModal(true);
            }} marginH-10 padding-16 row backgroundColor='#2ecc71' style={{
                flexBasis: '24%'
            }}>

                <CashInComponentIcon />
                <View marginL-8>
                    <Text darkCardTitleBig color='white'>
                        {/* {(totalPaymentReceived || 0).toFixed(0)} */}
                        {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format((totalPaymentReceived || 0))}
                    </Text>
                    <View>
                        <Text lightCardTitle color='white'>Cash Inflow</Text>
                    </View>
                </View>
                {showPaymentReceivedDetailModal && <CustomModal
                    visible={true}
                    onClose={() => {
                        setShowPaymentReceivedDetailModal(false);
                    }}
                    title="Payment Received Detail"
                    width={500}
                    height={500}
                >
                    {paymentDetailsArray.map((item, index) => {
                        return <View row spread key={index} style={{
                            borderTopWidth: 1,
                            borderTopColor: '#f0f0f0',
                            paddingVertical: 4,
                            marginTop: (item.name == "Discount Given") ? 50 : 0
                        }}>
                            <Text darkCardTitle>{item.name}</Text>
                            <Text darkCardTitle>{item.value}</Text>
                        </View>
                    })}

                    <View style={{
                        width: '100%',
                        height: 1,
                        borderBottomWidth: 1,
                        borderBottomColor: '#f0f0f0'
                    }}></View>

                    <SecondaryButton style={{
                        marginTop: 10
                    }} onPress={() => {
                        setShowPaymentReceivedDetailModal(false);
                    }}>
                        <Text>Close</Text>
                    </SecondaryButton>
                </CustomModal>}
            </Card>
            <Card onPress={() => {
                setShowPaymentMadeDetailModal(true);
            }} marginH-10 padding-16 backgroundColor='#e74c3c' row style={{
                flexBasis: '24%'
            }}>
                <CashOutComponentIcon />

                <View marginL-8>
                    <Text darkCardTitleBig color='white'>
                        {/* {(totalPaymentMade || 0).toFixed(0)} */}
                        {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format((totalPaymentMade || 0))}
                    </Text>
                    <View>
                        <Text lightCardTitle color='white'>Cash Outflow</Text>
                    </View>
                </View>

                {showPaymentMadeDetailModal && <CustomModal
                    visible={true}
                    onClose={() => {
                        setShowPaymentMadeDetailModal(false);
                    }}
                    title="Payment Made Detail"
                    width={500}
                    height={500}
                >
                    {paymentMadeDetailsArray.map((item, index) => {
                        return <View row spread key={index} style={{
                            borderTopWidth: 1,
                            borderTopColor: '#f0f0f0',
                            paddingVertical: 4,
                            marginTop: (item.name == "Discount Given") ? 50 : 0
                        }}>
                            <Text darkCardTitle>{item.name}</Text>
                            <Text darkCardTitle>{item.value}</Text>
                        </View>
                    })}

                    <View style={{
                        width: '100%',
                        height: 1,
                        borderBottomWidth: 1,
                        borderBottomColor: '#f0f0f0'
                    }}></View>

                    <SecondaryButton style={{
                        marginTop: 10
                    }} onPress={() => {
                        setShowPaymentMadeDetailModal(false);
                    }}>
                        <Text>Close</Text>
                    </SecondaryButton>
                </CustomModal>}
            </Card>
        </View>

        <View row marginT-20>
            <View marginR-10 style={{
                flexBasis: '75%'
            }}>
                <Card padding-16>
                    <Text darkCardTitleBig>Sales</Text>
                    <Chart
                        key={reloadChart}
                        options={{
                            chart: {
                                id: 'apexchart-example'
                            },
                            xaxis: {
                                categories: salesChart.xaxis
                            },
                            dataLabels: {
                                enabled: false
                            },
                            stroke: {
                                curve: 'smooth'
                            },
                            toolbar: {
                                show: false,
                                zoom: {
                                    enabled: false
                                }
                            },
                            zoom: {
                                enabled: false
                            }
                        }}
                        series={[
                            {
                                name: 'series1',
                                data: salesChart.data
                            },
                        ]}
                        type="area"
                        width="100%"
                        height={400}
                        toolbar={{
                            show: false,
                            zoom: {
                                enabled: false
                            }
                        }}
                        zoom={{
                            enabled: false
                        }}
                    />
                </Card>
            </View>
            <View marginL-10 style={{
                flexBasis: '24%'
            }}>
                <Card padding-16>
                    <Text marginB-10 darkCardTitleBig>Top Products</Text>
                    <ScrollView style={{
                        height: 405,
                        width: '100%'
                    }}>
                        {topProducts.map((item, index) => {
                            return <View marginT-10 row key={index} style={{
                                width: '100%'
                            }}>
                                <View>
                                    <Avatar size={40} label={
                                        index + 1 + ''
                                    } />
                                </View>
                                <View flexG>
                                    <View marginL-10 >
                                        <Text darkCardTitle>
                                            {item.item_name}
                                        </Text>
                                    </View>
                                    <View marginL-10 row spread>
                                        <Text lightCardTitle> {item.quantity} Sold</Text>
                                        <Text lightCardTitle> ₹ {RND(item.amount)}</Text>
                                    </View>
                                </View>
                            </View>
                        })}
                    </ScrollView>
                </Card>
            </View>
        </View>

    </View>);
}

export default HomeDashboard;
