// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useRef, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions, RefreshControl, DeviceEventEmitter, StyleSheet, TextInput } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, Avatar, TouchableOpacity } from 'react-native-ui-lib';
import DynamicForm, { Col, CustomModal, CustomNumberInput, CustomSelect, DangerButton, Heading, Row, SecondaryButton, CustomTextField, Label, CustomCheckbox, CustomUpload } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../mycomponents/state"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import { GlobalMasterStore } from "../../../../../mycomponents/state/PermissionStore"
import dayjs from "dayjs"
import { Camera, CameraType } from 'expo-camera';
import Svg, { G, Path } from "react-native-svg"
import Header from "./Header"
import Footer from "./Footer"
import { Icon } from "react-native-eva-icons"
import { create } from 'zustand';
import { invoiceItemAdjustment } from "../../../../../models/accountancy/transaction"
import { App } from 'antd';
import TabPane from "antd/es/tabs/TabPane"
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, createUserWithEmailAndPassword } from 'firebase/auth';

const isDesktop = Dimensions.get('window').width >= 768;

const useFormObject = create((set, get) => ({
    formObject: {},
    setFormObject: (keyValue) => set((state) => ({ formObject: { ...state.formObject, ...keyValue } })),
    setFormObjectArray: (array_key, array_index, field_key, value) => set((state) => {
        if (typeof field_key === 'object') {
            const newFormObject = { ...state.formObject };
            newFormObject[array_key][array_index] = { ...newFormObject[array_key][array_index], ...field_key };
            return { formObject: newFormObject };
        } else {
            const newFormObject = { ...state.formObject };
            // newFormObject[array_key][array_index][field_key] = value;
            const newArray = newFormObject[array_key];
            newArray[array_index][field_key] = value;
            newFormObject[array_key] = newArray;
            return { formObject: newFormObject };
        }
    }),
    getFormObjectArrayValue: (array_key, array_index, field_key) => get().formObject[array_key][array_index][field_key],
    getFormObject: () => get().formObject,
}))

const firebaseConfig = {
    apiKey: "AIzaSyB9FLfsQFjuRL0tsiAicPWiD8NKIyzoAEA",
    authDomain: "researchist-ff0ed.firebaseapp.com",
    projectId: "researchist-ff0ed",
    storageBucket: "researchist-ff0ed.appspot.com",
    messagingSenderId: "438350205219",
    appId: "1:438350205219:web:f2d0f62da5b3191ac4bc96",
    measurementId: "G-QFYF2S23VE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const MyAccount = (props) => {
    const { message, modal } = App.useApp();

    const cart = GlobalMasterStore(state => state.cart);
    const setCart = GlobalMasterStore(state => state.setCart);

    const [phone, setPhone] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [country, setCountry] = useState("India");
    const [name, setName] = useState("");
    const [image, setImage] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [address, setAddress] = useState("");
    const [apartment, setApartment] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [pincode, setPincode] = useState("");
    const [quantity, setQuantity] = useState(1);
    const [paymentType, setPaymentType] = useState("Razorpay");

    let navigation = useNavigation();
    let navigate = null;

    const formObject = useFormObject((state) => state.formObject);
    const getFormObject = useFormObject((state) => state.getFormObject);
    const setArrValue = useFormObject((state) => state.setFormObjectArray);
    const setFormObject = useFormObject((state) => state.setFormObject);
    const setGlobalData = GlobalMasterStore((state) => state.setGlobalData);
    const globalData = GlobalMasterStore((state) => state.globalData);

    const [products, setProducts] = useState([]);

    const OpenModalCustom = ({ newModule, field_key, array = false, params = null }) => {
        DeviceEventEmitter.addListener("reloadData" + "POS", (event) => {
            if (!event) {
                DeviceEventEmitter.removeAllListeners("reloadData" + "POS");
                if (Platform.OS == 'web') {
                    setOpenModal({ [moduleName]: null })
                } else {

                    navigation.pop();
                }
                return;
            }

            const newObj = getFormObject();

            if (event?.deleted) {
                DeviceEventEmitter.removeAllListeners("reloadData" + "POS");
                let object = { ...newObj };

                if (event?.id) {
                    if (array) {
                        const oldValues = object[field_key] ? object[field_key] : [];
                        object[field_key] = oldValues.filter((id) => id != event?.id);
                    } else {
                        object[field_key] = null;
                    }
                }

                getModuleCreateData({ ...object, disable_auto_line_item: true });

                if (Platform.OS == 'web') {
                    setOpenModal({
                        [newModule]: null
                    })
                } else {
                    goBack();
                }
                return;
            }

            let object = { ...newObj };

            if (array) {
                const oldValues = object[field_key] ? object[field_key] : [];
                object[field_key] = [...new Set([...oldValues, event._id])]
            } else {
                object[field_key] = event._id;
            }

            getModuleCreateData({ ...object, disable_auto_line_item: true });

            if (Platform.OS == 'web') {
                setOpenModal({
                    [newModule]: null
                })
            } else {
                goBack();
            }

            DeviceEventEmitter.removeAllListeners("reloadData" + "POS");
        })

        if (Platform.OS == 'web') {
            let data = {
                [newModule]: {
                    isVisible: true,
                    moduleName: [newModule],
                    viewOnly: false,
                    afterSaveEvent: 'reloadData' + "POS",
                    ignoreQueryParams: true,
                    ...(params ? params : {})
                }
            };

            setOpenModal(data)
        } else {
            navigationFn('New', {
                moduleName: newModule,
                afterSaveEvent: 'reloadData' + "POS",
                ...(params ? params : {})
            })
        }
    }

    const moduleName = "Invoices";
    const { fields, dataTabs, preferencesFields, ExtraSaveComponent } = api.getForm(moduleName);
    const params = {};

    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.navigate("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.navigate(path, params);
            }
        }
    };

    const [activeSegment, setActiveSegment] = useState("profile");
    const [categoriesOptions, setCategoriesOptions] = useState([]);
    

    const login = async (email, password) => {
        if (!email) {
            if (Platform.OS === 'web') {
                message.error("Please enter email");
            } else {
                Alert.alert("Error", "Please enter email");
            }
            return;
        }

        if (!password) {
            if (Platform.OS === 'web') {
                message.error("Please enter password");
            } else {
                Alert.alert("Error", "Please enter password");
            }
            return;
        }

        const auth = getAuth();

        try {
            // Attempt to sign in
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            // If successful, proceed with login
            proceedWithLogin(email, userCredential.user.uid);
        } catch (error) {
            if (error.code === 'auth/invalid-login-credentials') {
                // User doesn't exist, attempt to sign up
                try {
                    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
                    // If successful, proceed with login
                    proceedWithLogin(email, userCredential.user.uid);
                } catch (signUpError) {
                    handleAuthError(signUpError);
                }
            } else {
                handleAuthError(error);
            }
        }
    };

    const proceedWithLogin = async (email, uid) => {
        const res = await api.customRoute("shop/createCustomerIfNotExistsEmail", {
            email: email,
            uid: uid,
        })

        setGlobalData({
            email: email,
            is_approved_seller: res.data.is_approved_seller,
        })
    };

    const handleAuthError = (error) => {
        let errorMessage = "An error occurred. Please try again.";

        if (error.code === 'auth/wrong-password') {
            errorMessage = "Incorrect password. Please try again.";
        } else if (error.code === 'auth/invalid-email') {
            errorMessage = "Invalid email address.";
        } else if (error.code === 'auth/weak-password') {
            errorMessage = "Password should be at least 6 characters.";
        } else if (error.code === 'auth/email-already-in-use') {
            errorMessage = "Email is already in use. Please try logging in.";
        }

        if (Platform.OS === 'web') {
            message.error(errorMessage);
        } else {
            Alert.alert("Error", errorMessage);
        }
    };

    const getCustomerProfileByEmail = async () => {
        const response = await api.customRoute("shop/getCustomerProfileByEmail", {
            email: globalData.email,
        });

        if (response.data) {
            const data = response.data;
            setName(data.name);
            setPhone(data.phone);
            setCompanyName(data.company_name);
            setImage(data.image);
            
            if (data.addresses) {
                setAddress(data.addresses[0].address_line_1);
                setApartment(data.addresses[0].address_line_2);
                setCity(data.addresses[0].city);
                setState(data.addresses[0].state);
                setPincode(data.addresses[0].pincode);
            }

            setGlobalData({
                seller_id: data._id,
            })
        }

        setGlobalData({
            is_approved_seller: response.data.is_approved_seller,
        });
    }

    const saveCustomerProfileByEmail = async () => {
        const response = await api.customRoute("shop/saveCustomerProfileByEmail", {
            email: globalData.email,
            name: name,
            phone: phone,
            image: image,
            company_name: companyName,
            addresses: [{
                address_line_1: address,
                address_line_2: apartment,
                city: city,
                state: state,
                pincode: pincode,
            }]
        });

        if (response.success) {
            message.success("Profile updated successfully");
        }
    };

    useEffect(() => {
        if (globalData.email) {
            setEmail(globalData.email);
        }

        getCustomerProfileByEmail();
    }, [globalData.email]);

    // Add these to your component's state
    const [selectedProducts, setSelectedProducts] = useState({});
    const [prices, setPrices] = useState({});

    // Add these handler functions
    const handleProductSelect = async (productId, value) => {
        const newSelection = { ...selectedProducts, [productId]: value };
        setSelectedProducts(newSelection);

        if (!value) {
            const newPrices = { ...prices };
            delete newPrices[productId];
            setPrices(newPrices);
        }

        await updateProductSettings(productId, value, prices[productId]);
    };

    const handlePriceChange = async (productId, price) => {
        const newPrices = { ...prices, [productId]: price };
        setPrices(newPrices);

        if (selectedProducts[productId]) {
            await updateProductSettings(productId, true, price);
        }
    };

    const getProducts = async () => {
        let meta = {};

        if(globalData.selectedCategories?.length > 0) {
            meta = {
                categories: globalData.selectedCategories
            }
        }

        const response = await api.customRoute("shop/getProducts", {
            search: "",
            minPrice: 0,
            maxPrice: 100000,
            page: 1,
            perPage: 10000,
            meta: meta,
            category_name: "",
        });


        if(globalData.selectedCategories?.length > 0) {
            setProducts(response.data.data);
            let selectedProducts = {};
            let prices = {};

            for(let product of response.data.data) {
                const productSeller = product.sellers.find(seller => seller.seller_id == globalData.seller_id);
                if(productSeller) {
                    selectedProducts[product._id] = true;
                    prices[product._id] = productSeller.price
                } else {
                    selectedProducts[product._id] = false;
                    prices[product._id] = product.sales_rate;
                }
            }

            setSelectedProducts(selectedProducts);
            setPrices(prices);
        }

        setCategoriesOptions(response.data.ItemCategories);
    };

    useEffect(() => {
        getProducts();
    }, [globalData.selectedCategories]);

    const updateProductSettings = async (productId, isSelected, price) => {
        if(isSelected && !price) {
            return;
        }

        try {
            await api.customRoute("shop/updateSellerAndProduct", {
                seller_id: globalData.seller_id,
                product_id: productId,
                selling: isSelected,
                price: +(price || 0),
            });

            //message.success("Product settings updated successfully");
        } catch (error) {
            console.error('Error updating product settings:', error);
        }
    };

    return (<>
        <ScrollView style={{
            height: Dimensions.get("window").height,
        }}>
            <Card>
                <Header />
                <View style={[{
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#fff",
                    overflow: "hidden",
                    height: 70,
                    minHeight: 70,
                    borderWidth: 1,
                    borderColor: "#e5e7eb",
                }]}>
                </View>
                <View center>
                    <View style={{
                        maxWidth: 1200,
                        width: '100%',
                    }}>
                        <Row>
                            <Col width={isDesktop ? "100%" : '100%'} style={{
                                padding: isDesktop ? 60 : 30,
                            }}>

                                {!globalData.email ? <>
                                    <View center style={{ width: '100%' }}>
                                        <View style={{
                                            width: 400,
                                        }}>
                                            <Text text40 marginB-20>Login or Create a free account</Text>
                                            <Text marginB-20>
                                                Create a free account to sell products on our platform, view and track your sales orders and purchase orders, and more.
                                            </Text>


                                            <View style={{
                                                width: "100%",
                                            }}>
                                                {Label({ label: "Email" })}
                                                <CustomTextField type={"email"} value={email} onChange={(value) => {
                                                    setEmail(value);
                                                }} />
                                            </View>
                                            <View marginT-5 marginB-10 style={{
                                                width: "100%",
                                            }}>
                                                {Label({ label: "Password" })}
                                                <CustomTextField type="password" value={password} onChange={(value) => {
                                                    setPassword(value);
                                                }} />
                                            </View>

                                            <Button label="Sign In" backgroundColor="#000" onPress={() => {
                                                login(email, password);
                                            }} />

                                            <View row center marginB-20 marginT-20 style={{
                                                position: 'relative'
                                            }}>
                                                <View style={{
                                                    backgroundColor: 'white',
                                                    paddingHorizontal: 10,
                                                    zIndex: 1,
                                                }}>
                                                    <Text>OR</Text>
                                                </View>

                                                <View style={{
                                                    position: 'absolute',
                                                    left: 0,
                                                    right: 0,
                                                    height: 1,
                                                    top: '50%',
                                                    backgroundColor: '#e3e3e3',
                                                }}></View>
                                            </View>

                                            {/* <Button label="Sign up with Google" backgroundColor="#fff" color="#000" outlineColor="#000" marginB-10 /> */}
                                            <SecondaryButton onPress={() => {
                                                const handleGoogleSignIn = async () => {
                                                    const provider = new GoogleAuthProvider();
                                                    try {
                                                        const res = await signInWithPopup(auth, provider);
                                                        console.log(res);
                                                        const email = res.user.email;
                                                        const uid = res.user.uid;
                                                        proceedWithLogin(email, uid);
                                                    } catch (error) {
                                                        console.error(error);
                                                    }
                                                };

                                                handleGoogleSignIn();
                                            }}>
                                                <View row center>
                                                    <img crossorigin="anonymous" srcset="https://img.clerk.com/static/google.svg?width=80 1x,https://img.clerk.com/static/google.svg?width=160 2x" src="https://img.clerk.com/static/google.svg?width=160" class="cl-socialButtonsProviderIcon cl-providerIcon cl-socialButtonsProviderIcon__google cl-providerIcon__google 🔒️ cl-internal-2gzuzc" alt="Sign in with Google" />
                                                    <Text marginL-10>Sign up with Google</Text>
                                                </View>
                                            </SecondaryButton>
                                        </View>
                                    </View>
                                </> : <>
                                    <View style={{
                                        width: '100%',
                                    }}>
                                        <View>
                                            <Text style={{
                                                fontSize: 30,
                                                fontFamily: 'SourceSansProBold',
                                                color: '#000',
                                            }}>My Account</Text>
                                        </View>
                                    </View>

                                    <View marginT-20>
                                        <SegmentedControl
                                            segments={[
                                                { label: 'My Profile', value: 'profile' },
                                                { label: 'My Products', value: 'products' },
                                                { label: 'My Sale Orders', value: 'sale_orders' },
                                                { label: 'My Purchase Orders', value: 'purchase_orders' },
                                            ]}
                                            activeColor={"#0b7aca"}
                                            borderRadius={4}
                                            onChangeIndex={(index) => {
                                                const indexMap = {
                                                    0: "profile",
                                                    1: "products",
                                                    2: "sale_orders",
                                                    3: "purchase_orders",
                                                }

                                                setActiveSegment(indexMap[index]);
                                            }}
                                        />
                                    </View>

                                    {activeSegment === "profile" && <>
                                        <View style={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            paddingTop: 20,
                                            paddingBottom: 15,
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}>
                                            <View>
                                                <Text style={{
                                                    fontSize: 16,
                                                    fontFamily: 'SourceSansProBold',
                                                    color: '#000',
                                                }}>
                                                    Contact
                                                </Text>
                                            </View>
                                            {/* <View>Have an account? Log in</View> */}
                                        </View>
                                        <View style={{
                                            paddingBottom: 15,
                                            width: '100%',
                                        }}>
                                            {/* {Label({ label: "Email" })} */}

                                            <View style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                                width: '100%',
                                                alignItems: 'center',
                                                paddingBottom: 15,
                                            }}>
                                                <View left paddingR-10 style={{
                                                    width: '100%',
                                                }}>
                                                    <CustomUpload
                                                        label={"Profile Picture"}
                                                        value={image}
                                                        mimeType={"image/*"}
                                                        moduleName={"Contacts"}
                                                        onChange={(value) => {
                                                            setImage(value);
                                                        }}
                                                    />
                                                </View>

                                                <View paddingR-10 style={{
                                                    width: '50%',
                                                }}>
                                                    <CustomTextField
                                                        placeholder={"Name"}
                                                        value={name}
                                                        onChange={(value) => {
                                                            setName(value);
                                                        }} />
                                                </View>
                                                <View style={{
                                                    width: '50%',
                                                }}>
                                                    <CustomTextField
                                                        placeholder={"Email"}
                                                        value={email}
                                                        onChange={(value) => {
                                                            //setEmail(value);
                                                        }}
                                                    />
                                                </View>
                                            </View>

                                            <CustomTextField
                                                placeholder={"Phone Number"}
                                                value={phone}
                                                onChange={(value) => {
                                                    setPhone(value);
                                                }}
                                                showCountryCode
                                            />
                                        </View>

                                        <View style={{
                                            paddingBottom: 15,
                                        }}>
                                            <Text style={{
                                                fontSize: 16,
                                                fontFamily: 'SourceSansProBold',
                                                color: '#000',
                                            }}>
                                                Business Details
                                            </Text>
                                        </View>

                                        <View style={{
                                            width: '100%',
                                            paddingBottom: 15,
                                        }}>
                                            <CustomTextField
                                                placeholder={"Legal Name of Business"}
                                                value={companyName}
                                                onChange={(value) => {
                                                    setCompanyName(value);
                                                }} />
                                        </View>

                                        <View style={{
                                            width: '100%',
                                            paddingBottom: 15,
                                        }}>
                                            <CustomTextField
                                                placeholder={"Pincode"}
                                                value={pincode} onChange={(value) => {
                                                    setPincode(value);
                                                }} />
                                        </View>


                                        <View paddingB-15 style={{
                                            width: '100%',
                                        }}>
                                            <CustomTextField
                                                placeholder={"Address"}
                                                value={address}
                                                onChange={(value) => {
                                                    setAddress(value);
                                                }} />
                                        </View>

                                        <View paddingB-15 style={{
                                            width: '100%',
                                        }}>
                                            <CustomTextField
                                                placeholder={"Apartment, suite, etc"}
                                                value={apartment} onChange={(value) => {
                                                    setApartment(value);
                                                }} />
                                        </View>

                                        <View style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            width: '100%',
                                        }}>
                                            <View paddingR-10 style={{
                                                width: '50%',
                                            }}>
                                                <CustomTextField
                                                    placeholder={"City"}
                                                    value={city} onChange={(value) => {
                                                        setCity(value);
                                                    }} />
                                            </View>

                                            <View paddingR-10 style={{
                                                width: '50%',
                                            }}>
                                                <CustomSelect
                                                    placeholder={"State"}
                                                    options={STATES}
                                                    value={state}
                                                    onChange={(value) => {
                                                        setState(value);
                                                    }} />
                                            </View>

                                        </View>

                                        <View left>
                                            <TouchableOpacity onPress={() => {
                                                if (!name) {
                                                    message.error("Name is required");
                                                    return;
                                                } else if (!phone) {
                                                    message.error("Phone is required");
                                                    return;
                                                } else if (!address) {
                                                    message.error("Address is required");
                                                    return;
                                                } else if (!city) {
                                                    message.error("City is required");
                                                    return;
                                                } else if (!state) {
                                                    message.error("State is required");
                                                    return;
                                                } else if (!pincode) {
                                                    message.error("Pincode is required");
                                                    return;
                                                }

                                                if(!image) {
                                                    message.error("Profile picture is required");
                                                    return;
                                                }

                                                if (!companyName) {
                                                    message.error("Company Name is required");
                                                    return;
                                                }

                                                saveCustomerProfileByEmail();
                                            }}
                                                style={{
                                                    backgroundColor: '#0b7aca',
                                                    color: '#000',
                                                    padding: 10,
                                                    marginTop: 20,
                                                    width: '100%',
                                                    alignItems: 'center',
                                                    paddingVertical: 10,
                                                    paddingHorizontal: 30,
                                                    height: 40,
                                                    borderRadius: 4
                                                }}
                                            >
                                                <Text color="white"
                                                    style={{ fontFamily: 'SourceSansProSemiBold' }}
                                                >Save</Text>
                                            </TouchableOpacity>
                                        </View>
                                    </>}


                                    {activeSegment === "products" && (
                                        <View style={styles.productsContainer}>
                                            <View style={{ marginBottom: 20 }}>
                                                <Text 
                                                style={{ fontSize: 14, color: '#666', fontFamily: 'SourceSansProSemiBold' }}
                                                >What do you sell?</Text> 
                                                <View marginT-10>
                                                    <CustomSelect
                                                        placeholder={"Select a category"}
                                                        options={categoriesOptions.map((category) => ({
                                                            label: category.name,
                                                            value: category.name,
                                                        }))}
                                                        value={globalData.selectedCategories}
                                                        multiple
                                                        onChange={(value) => {
                                                            setGlobalData({
                                                                selectedCategories: value,
                                                            });
                                                        }}
                                                    />
                                                </View>
                                            </View>

                                            <View style={styles.tableHeader}>
                                                <Text style={[styles.headerCell, { flex: 0.5 }]}>Select</Text>
                                                <Text style={[styles.headerCell, { flex: 2 }]}>Product Name</Text>
                                                <Text style={[styles.headerCell, { flex: 1 }]}>Sale Price</Text>
                                            </View>
                                            {products.map((product) => (
                                                <View key={product._id} style={styles.tableRow}>
                                                    <View style={[styles.cell, { flex: 0.5 }]}>
                                                        <Checkbox
                                                            color={"#0b7aca"}
                                                            size={18}
                                                            value={selectedProducts[product._id]}
                                                            onValueChange={(value) => handleProductSelect(product._id, value)}
                                                        />
                                                    </View>
                                                    <Text style={[styles.cell, { flex: 2 }]}>{product.name}</Text>
                                                    <View style={[styles.cell, { flex: 1 }]}>
                                                        <TextInput
                                                            style={styles.priceInput}
                                                            value={prices[product._id]}
                                                            onChangeText={(text) => handlePriceChange(product._id, text)}
                                                            keyboardType="numeric"
                                                            placeholder="0.00"
                                                            disabled={!selectedProducts[product._id]}
                                                        />
                                                    </View>
                                                </View>
                                            ))}
                                        </View>
                                    )}

                                </>}
                            </Col>
                        </Row>
                    </View>
                </View>

                <Footer />
            </Card>
        </ScrollView>
    </>);
};

export default MyAccount;

// Add these styles
const styles = StyleSheet.create({
    productsContainer: {
        padding: 10,
        width: '100%',
    },
    tableHeader: {
        flexDirection: 'row',
        backgroundColor: '#f5f5f5',
        padding: 10,
        marginBottom: 5,
    },
    headerCell: {
        fontWeight: 'bold',
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: '#eee',
        padding: 10,
        alignItems: 'center',
    },
    cell: {
        padding: 5,
    },
    priceInput: {
        borderWidth: 1,
        borderColor: '#ddd',
        borderRadius: 4,
        padding: 5,
        width: '100%',
    },
});

const STATES = [
    {
        "_id": "653399d6f16f979e73ca92a0",
        "id": 4023,
        "name": "Andaman and Nicobar Islands",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "AN",
        "type": "Union territory",
        "latitude": "11.74008670",
        "longitude": "92.65864010",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.169Z",
        "updatedAt": "2023-10-21T09:28:54.169Z",
        "__v": 0,
        "label": "Andaman and Nicobar Islands",
        "value": "Andaman and Nicobar Islands"
    },
    {
        "_id": "653399d6f16f979e73ca92a3",
        "id": 4017,
        "name": "Andhra Pradesh",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "AP",
        "type": "state",
        "latitude": "15.91289980",
        "longitude": "79.73998750",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.174Z",
        "updatedAt": "2023-10-21T09:28:54.174Z",
        "__v": 0,
        "label": "Andhra Pradesh",
        "value": "Andhra Pradesh"
    },
    {
        "_id": "653399d6f16f979e73ca92a6",
        "id": 4024,
        "name": "Arunachal Pradesh",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "AR",
        "type": "state",
        "latitude": "28.21799940",
        "longitude": "94.72775280",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.179Z",
        "updatedAt": "2023-10-21T09:28:54.179Z",
        "__v": 0,
        "label": "Arunachal Pradesh",
        "value": "Arunachal Pradesh"
    },
    {
        "_id": "653399d6f16f979e73ca92a9",
        "id": 4027,
        "name": "Assam",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "AS",
        "type": "state",
        "latitude": "26.20060430",
        "longitude": "92.93757390",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.182Z",
        "updatedAt": "2023-10-21T09:28:54.182Z",
        "__v": 0,
        "label": "Assam",
        "value": "Assam"
    },
    {
        "_id": "653399d6f16f979e73ca92ac",
        "id": 4037,
        "name": "Bihar",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "BR",
        "type": "state",
        "latitude": "25.09607420",
        "longitude": "85.31311940",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.187Z",
        "updatedAt": "2023-10-21T09:28:54.187Z",
        "__v": 0,
        "label": "Bihar",
        "value": "Bihar"
    },
    {
        "_id": "653399d6f16f979e73ca92af",
        "id": 4031,
        "name": "Chandigarh",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "CH",
        "type": "Union territory",
        "latitude": "30.73331480",
        "longitude": "76.77941790",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.191Z",
        "updatedAt": "2023-10-21T09:28:54.191Z",
        "__v": 0,
        "label": "Chandigarh",
        "value": "Chandigarh"
    },
    {
        "_id": "653399d6f16f979e73ca92b2",
        "id": 4040,
        "name": "Chhattisgarh",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "CT",
        "type": "state",
        "latitude": "21.27865670",
        "longitude": "81.86614420",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.195Z",
        "updatedAt": "2023-10-21T09:28:54.195Z",
        "__v": 0,
        "label": "Chhattisgarh",
        "value": "Chhattisgarh"
    },
    {
        "_id": "653399d6f16f979e73ca92b5",
        "id": 4033,
        "name": "Dadra and Nagar Haveli and Daman and Diu",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "DH",
        "type": "Union territory",
        "latitude": "20.39737360",
        "longitude": "72.83279910",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.199Z",
        "updatedAt": "2023-10-21T09:28:54.199Z",
        "__v": 0,
        "label": "Dadra and Nagar Haveli and Daman and Diu",
        "value": "Dadra and Nagar Haveli and Daman and Diu"
    },
    {
        "_id": "653399d6f16f979e73ca92b8",
        "id": 4021,
        "name": "Delhi",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "DL",
        "type": "Union territory",
        "latitude": "28.70405920",
        "longitude": "77.10249020",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.204Z",
        "updatedAt": "2023-10-21T09:28:54.204Z",
        "__v": 0,
        "label": "Delhi",
        "value": "Delhi"
    },
    {
        "_id": "653399d6f16f979e73ca92bb",
        "id": 4009,
        "name": "Goa",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "GA",
        "type": "state",
        "latitude": "15.29932650",
        "longitude": "74.12399600",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.207Z",
        "updatedAt": "2023-10-21T09:28:54.207Z",
        "__v": 0,
        "label": "Goa",
        "value": "Goa"
    },
    {
        "_id": "653399d6f16f979e73ca92be",
        "id": 4030,
        "name": "Gujarat",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "GJ",
        "type": "state",
        "latitude": "22.25865200",
        "longitude": "71.19238050",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.212Z",
        "updatedAt": "2023-10-21T09:28:54.212Z",
        "__v": 0,
        "label": "Gujarat",
        "value": "Gujarat"
    },
    {
        "_id": "653399d6f16f979e73ca92c1",
        "id": 4007,
        "name": "Haryana",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "HR",
        "type": "state",
        "latitude": "29.05877570",
        "longitude": "76.08560100",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.217Z",
        "updatedAt": "2023-10-21T09:28:54.217Z",
        "__v": 0,
        "label": "Haryana",
        "value": "Haryana"
    },
    {
        "_id": "653399d6f16f979e73ca92c4",
        "id": 4020,
        "name": "Himachal Pradesh",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "HP",
        "type": "state",
        "latitude": "31.10482940",
        "longitude": "77.17339010",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.223Z",
        "updatedAt": "2023-10-21T09:28:54.223Z",
        "__v": 0,
        "label": "Himachal Pradesh",
        "value": "Himachal Pradesh"
    },
    {
        "_id": "653399d6f16f979e73ca92c7",
        "id": 4029,
        "name": "Jammu and Kashmir",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "JK",
        "type": "Union territory",
        "latitude": "33.27783900",
        "longitude": "75.34121790",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.228Z",
        "updatedAt": "2023-10-21T09:28:54.228Z",
        "__v": 0,
        "label": "Jammu and Kashmir",
        "value": "Jammu and Kashmir"
    },
    {
        "_id": "653399d6f16f979e73ca92ca",
        "id": 4025,
        "name": "Jharkhand",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "JH",
        "type": "state",
        "latitude": "23.61018080",
        "longitude": "85.27993540",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.233Z",
        "updatedAt": "2023-10-21T09:28:54.233Z",
        "__v": 0,
        "label": "Jharkhand",
        "value": "Jharkhand"
    },
    {
        "_id": "653399d6f16f979e73ca92cd",
        "id": 4026,
        "name": "Karnataka",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "KA",
        "type": "state",
        "latitude": "15.31727750",
        "longitude": "75.71388840",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.239Z",
        "updatedAt": "2023-10-21T09:28:54.239Z",
        "__v": 0,
        "label": "Karnataka",
        "value": "Karnataka"
    },
    {
        "_id": "653399d6f16f979e73ca92d0",
        "id": 4028,
        "name": "Kerala",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "KL",
        "type": "state",
        "latitude": "10.85051590",
        "longitude": "76.27108330",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.244Z",
        "updatedAt": "2023-10-21T09:28:54.244Z",
        "__v": 0,
        "label": "Kerala",
        "value": "Kerala"
    },
    {
        "_id": "653399d6f16f979e73ca92d3",
        "id": 4852,
        "name": "Ladakh",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "LA",
        "type": "Union territory",
        "latitude": "34.22684750",
        "longitude": "77.56194190",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.252Z",
        "updatedAt": "2023-10-21T09:28:54.252Z",
        "__v": 0,
        "label": "Ladakh",
        "value": "Ladakh"
    },
    {
        "_id": "653399d6f16f979e73ca92d6",
        "id": 4019,
        "name": "Lakshadweep",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "LD",
        "type": "Union territory",
        "latitude": "10.32802650",
        "longitude": "72.78463360",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.258Z",
        "updatedAt": "2023-10-21T09:28:54.258Z",
        "__v": 0,
        "label": "Lakshadweep",
        "value": "Lakshadweep"
    },
    {
        "_id": "653399d6f16f979e73ca92d9",
        "id": 4039,
        "name": "Madhya Pradesh",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "MP",
        "type": "state",
        "latitude": "22.97342290",
        "longitude": "78.65689420",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.265Z",
        "updatedAt": "2023-10-21T09:28:54.265Z",
        "__v": 0,
        "label": "Madhya Pradesh",
        "value": "Madhya Pradesh"
    },
    {
        "_id": "653399d6f16f979e73ca92dc",
        "id": 4008,
        "name": "Maharashtra",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "MH",
        "type": "state",
        "latitude": "19.75147980",
        "longitude": "75.71388840",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.273Z",
        "updatedAt": "2023-10-21T09:28:54.273Z",
        "__v": 0,
        "label": "Maharashtra",
        "value": "Maharashtra"
    },
    {
        "_id": "653399d6f16f979e73ca92df",
        "id": 4010,
        "name": "Manipur",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "MN",
        "type": "state",
        "latitude": "24.66371730",
        "longitude": "93.90626880",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.279Z",
        "updatedAt": "2023-10-21T09:28:54.279Z",
        "__v": 0,
        "label": "Manipur",
        "value": "Manipur"
    },
    {
        "_id": "653399d6f16f979e73ca92e2",
        "id": 4006,
        "name": "Meghalaya",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "ML",
        "type": "state",
        "latitude": "25.46703080",
        "longitude": "91.36621600",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.285Z",
        "updatedAt": "2023-10-21T09:28:54.285Z",
        "__v": 0,
        "label": "Meghalaya",
        "value": "Meghalaya"
    },
    {
        "_id": "653399d6f16f979e73ca92e5",
        "id": 4036,
        "name": "Mizoram",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "MZ",
        "type": "state",
        "latitude": "23.16454300",
        "longitude": "92.93757390",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.291Z",
        "updatedAt": "2023-10-21T09:28:54.291Z",
        "__v": 0,
        "label": "Mizoram",
        "value": "Mizoram"
    },
    {
        "_id": "653399d6f16f979e73ca92e8",
        "id": 4018,
        "name": "Nagaland",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "NL",
        "type": "state",
        "latitude": "26.15843540",
        "longitude": "94.56244260",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.295Z",
        "updatedAt": "2023-10-21T09:28:54.295Z",
        "__v": 0,
        "label": "Nagaland",
        "value": "Nagaland"
    },
    {
        "_id": "653399d6f16f979e73ca92eb",
        "id": 4013,
        "name": "Odisha",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "OR",
        "type": "state",
        "latitude": "20.95166580",
        "longitude": "85.09852360",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.300Z",
        "updatedAt": "2023-10-21T09:28:54.300Z",
        "__v": 0,
        "label": "Odisha",
        "value": "Odisha"
    },
    {
        "_id": "653399d6f16f979e73ca92ee",
        "id": 4011,
        "name": "Puducherry",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "PY",
        "type": "Union territory",
        "latitude": "11.94159150",
        "longitude": "79.80831330",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.305Z",
        "updatedAt": "2023-10-21T09:28:54.305Z",
        "__v": 0,
        "label": "Puducherry",
        "value": "Puducherry"
    },
    {
        "_id": "653399d6f16f979e73ca92f1",
        "id": 4015,
        "name": "Punjab",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "PB",
        "type": "state",
        "latitude": "31.14713050",
        "longitude": "75.34121790",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.311Z",
        "updatedAt": "2023-10-21T09:28:54.311Z",
        "__v": 0,
        "label": "Punjab",
        "value": "Punjab"
    },
    {
        "_id": "653399d6f16f979e73ca92f4",
        "id": 4014,
        "name": "Rajasthan",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "RJ",
        "type": "state",
        "latitude": "27.02380360",
        "longitude": "74.21793260",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.316Z",
        "updatedAt": "2023-10-21T09:28:54.316Z",
        "__v": 0,
        "label": "Rajasthan",
        "value": "Rajasthan"
    },
    {
        "_id": "653399d6f16f979e73ca92f7",
        "id": 4034,
        "name": "Sikkim",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "SK",
        "type": "state",
        "latitude": "27.53297180",
        "longitude": "88.51221780",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.321Z",
        "updatedAt": "2023-10-21T09:28:54.321Z",
        "__v": 0,
        "label": "Sikkim",
        "value": "Sikkim"
    },
    {
        "_id": "653399d6f16f979e73ca92fa",
        "id": 4035,
        "name": "Tamil Nadu",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "TN",
        "type": "state",
        "latitude": "11.12712250",
        "longitude": "78.65689420",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.326Z",
        "updatedAt": "2023-10-21T09:28:54.326Z",
        "__v": 0,
        "label": "Tamil Nadu",
        "value": "Tamil Nadu"
    },
    {
        "_id": "653399d6f16f979e73ca92fd",
        "id": 4012,
        "name": "Telangana",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "TG",
        "type": "state",
        "latitude": "18.11243720",
        "longitude": "79.01929970",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.331Z",
        "updatedAt": "2023-10-21T09:28:54.331Z",
        "__v": 0,
        "label": "Telangana",
        "value": "Telangana"
    },
    {
        "_id": "653399d6f16f979e73ca9300",
        "id": 4038,
        "name": "Tripura",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "TR",
        "type": "state",
        "latitude": "23.94084820",
        "longitude": "91.98815270",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.336Z",
        "updatedAt": "2023-10-21T09:28:54.336Z",
        "__v": 0,
        "label": "Tripura",
        "value": "Tripura"
    },
    {
        "_id": "653399d6f16f979e73ca9303",
        "id": 4022,
        "name": "Uttar Pradesh",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "UP",
        "type": "state",
        "latitude": "26.84670880",
        "longitude": "80.94615920",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.341Z",
        "updatedAt": "2023-10-21T09:28:54.341Z",
        "__v": 0,
        "label": "Uttar Pradesh",
        "value": "Uttar Pradesh"
    },
    {
        "_id": "653399d6f16f979e73ca9306",
        "id": 4016,
        "name": "Uttarakhand",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "UT",
        "type": "state",
        "latitude": "30.06675300",
        "longitude": "79.01929970",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.346Z",
        "updatedAt": "2023-10-21T09:28:54.346Z",
        "__v": 0,
        "label": "Uttarakhand",
        "value": "Uttarakhand"
    },
    {
        "_id": "653399d6f16f979e73ca9309",
        "id": 4853,
        "name": "West Bengal",
        "country_id": 101,
        "country_code": "IN",
        "country_name": "India",
        "state_code": "WB",
        "type": "state",
        "latitude": "22.98675690",
        "longitude": "87.85497550",
        "version": 1,
        "created_by_id": null,
        "updated_by_id": null,
        "approvalStatus": "Approved",
        "createdAt": "2023-10-21T09:28:54.351Z",
        "updatedAt": "2023-10-21T09:28:54.351Z",
        "__v": 0,
        "label": "West Bengal",
        "value": "West Bengal"
    }
]
